import {classNames} from "../../utilities/generali";

const TextArea = ({
                      name,
                      label,
                      value,
                      setValue,
                      rows = 3,
                      isValid = true,
                      invalidText = "",
                      className = "",
                      ...others
                  }) => {
    //console.log(isValid);
    return (
        <>
            {label && (
                <label
                    htmlFor={name}
                    className="block text-sm font-medium text-gray-700"
                >
                    {label}
                </label>
            )}
            <textarea
                id={name}
                name={name}
                rows={rows}
                className={classNames(
                    isValid
                        ? "border border-gray-300 focus:border-casalucci focus:ring-casalucci"
                        : "border-2 border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500",
                    "mt-1 block w-full max-w-full rounded-md  shadow-sm disabled:cursor-not-allowed disabled:bg-gray-200 disabled:bg-opacity-80 sm:text-sm",
                    className
                )}
                value={value}
                onChange={setValue}
                {...others}
            />
            {!isValid && (
                <span className="mt-1 text-xs font-medium text-red-500">
          {invalidText}
        </span>
            )}
        </>
    );
};

export default TextArea;
