import Input from "./Input";
import { classNames } from "../../utilities/generali";

const EuroInput = ({
  label,
  className,
  errorMessage = "Campo obbligatorio",
  ...inputProps
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor="price"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}

      <div className="group relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">€</span>
        </div>
        <Input
          {...inputProps}
          className={classNames(
            className,
            "group-focus pl-7 invalid:mb-12 invalid:sm:mb-10"
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            Eur
          </span>
        </div>
        <p className="absolute sm:-mt-8 -mt-10 invisible peer-invalid:visible text-red-500 text-sm sm:whitespace-nowrap">
          {errorMessage}
        </p>
      </div>
    </>
  );
};

export default EuroInput;
