import { Link, useParams } from "react-router-dom";
import RisorseTable from "../components/RisorseTable";
import { useMemo, useState } from "react";
import { selectImpiantoById } from "../impiantiApiSlice";
import { useSelector } from "react-redux";
import useNavigation from "../../../hooks/useNavigation";
import AggiungiRisorseModal from "../AggiungiRisorseModal";
import SelectRisorseImpiantoModal from "../components/SelectRisorseImpiantoModal";
import { useGetRisorseQuery } from "../../risorse/risorseApiSlice";
import { selectCurrentUser } from "../../auth/authSlice";

const RisorseImpiantoPage = ({}) => {
  const { impiantoId } = useParams();

  const { isSuccess } = useGetRisorseQuery();

  const [open, setOpen] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const selector = useMemo(
    () => (state) => selectImpiantoById(state, Number(impiantoId)),
    [impiantoId]
  );

  const { nome, risorse } = useSelector(selector);
  const user = useSelector(selectCurrentUser);

  useNavigation([{ name: impiantoId, newName: nome }]);

  const handleAddRisorse = (newRisorse) => {
    setIngredients((prev) => [
      ...prev.filter((risorsa) => {
        if (newRisorse.includes(risorsa?.risorsa)) return risorsa;
      }),
      ...newRisorse
        .filter((risorsa) => !ingredients.some((obj) => obj.id === risorsa.id))
        .map((risorsa) => ({
          risorsa: risorsa,
          qta: "",
          id: risorsa.id,
        })),
    ]);
  };

  let content = (
    <div className="flex-1 flex flex-col">
      <section className="min-w-full ">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Risorse {nome}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Lista delle risorse {nome}.
              </p>
            </div>
            {user?.role === 104 || user?.role === 100 ? (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  onClick={() => setOpen(true)}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-casalucci px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2 sm:w-auto"
                >
                  Aggiungi Risorsa
                </button>
              </div>
            ) : undefined}
          </div>
          <RisorseTable risorse={risorse} />
        </div>
      </section>
    </div>
  );

  return (
    isSuccess && (
      <>
        <SelectRisorseImpiantoModal
          open={open}
          setOpen={setOpen}
          selectedRisorse={risorse}
        />
        {content}
      </>
    )
  );
};

export default RisorseImpiantoPage;
