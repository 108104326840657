import { useState } from "react";
import { useUpdateCantiereMutation } from "../cantieriApiSlice";
import { Link, useNavigate } from "react-router-dom";
import ErrorAlert from "../../../components/alerts/ErrorAlert";
import Input from "../../../components/forms/Input";
import EuroInput from "../../../components/forms/EuroInput";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../utilities/generali";
import TextArea from "../../../components/forms/TextArea";
import LoadingScreen from "../../../components/LoadingScreen";

const EditaCantiereForm = ({ existingCantiere }) => {
  const [cantiere, setCantiere] = useState(existingCantiere);
  const [error, setError] = useState({
    show: false,
    body: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const canSave = [cantiere.nome].every(Boolean);

  const [updateCantiere] = useUpdateCantiereMutation();

  const navigate = useNavigate();

  const onInputChange = (e) => {
    setCantiere({
      ...cantiere,
      [e.target.name]: e.target.value,
    });
  };

  const setPubblico = (value) => {
    setCantiere({
      ...cantiere,
      pubblico: value,
    });
  };

  const onSaveCantiereClicked = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (canSave) {
      setError({
        show: false,
        body: "",
      });

      try {
        setLoading(true);
        await updateCantiere({
          data: cantiere,
          id: existingCantiere.id,
        }).unwrap();

        navigate("/cantieri");
      } catch (err) {
        setLoading(false);
        setError({
          show: true,
          body: err?.data?.message,
        });
      }
    } else {
      setError({
        show: true,
        body: "Compila i campi obbligatori per poter salvare",
      });
    }
  };

  if (loading) return <LoadingScreen />;

  return (
    <section className="">
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveCantiereClicked}
      >
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 sm:p-6 bg-white space-y-8 divide-y divide-gray-200 sm:space-y-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edita Cantiere
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                I campi indicati con * sono obbligatori.
              </p>
              {error.show ? <ErrorAlert title={error.body} /> : null}
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="nome"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Nome*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="nome"
                    value={cantiere.nome}
                    setValue={onInputChange}
                    required={submitted}
                    errorMessage="Inserisci un nome per la risorsa"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="importo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Importo dei lavori
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <EuroInput
                    name="importo"
                    value={cantiere.importo}
                    setValue={onInputChange}
                    errorMessage="L'importo dei lavori deve essere un numero"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Importo netto dei lavori
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="indirizzo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Indirizzo
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="indirizzo"
                    value={cantiere.indirizzo}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <Switch.Group as="div">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:items-center">
                  <div>
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Pubblico
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        Il committente dei lavori è un ente pubblico.
                      </Switch.Description>
                    </span>
                  </div>

                  <div className="mt-4 sm:col-span-2 sm:mt-0">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <Switch
                            checked={cantiere.pubblico}
                            onChange={setPubblico}
                            className={classNames(
                              cantiere.pubblico
                                ? "bg-casalucci"
                                : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                cantiere.pubblico
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Switch.Group>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="descrizione"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Descrizione
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <TextArea
                    className="max-w-lg"
                    name="descrizione"
                    value={cantiere.descrizione}
                    setValue={onInputChange}
                  />

                  <p className="mt-2 text-sm text-gray-500 max-w-lg">
                    Descrizione dei lavori da eseguire
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">
            <Link
              to={`/cantieri/${cantiere.id}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditaCantiereForm;
