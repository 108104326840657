import Input from "../../../components/forms/Input";
import TextArea from "../../../components/forms/TextArea";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddNewImpiantoMutation } from "../impiantiApiSlice";
import Select from "../../../components/forms/Select";
import { TIPI_IMPIANTO } from "../constants";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../utilities/generali";
import LoadingScreen from "../../../components/LoadingScreen";

const NuovoImpiantoPage = () => {
  const navigate = useNavigate();

  const emptyImpianto = {
    nome: "",
    descrizione: "",
    indirizzo: "",
    foto: null,
    rifiuti: false,
  };

  const [impianto, setImpianto] = useState(emptyImpianto);
  const [selectedTipo, setSelectedTipo] = useState(TIPI_IMPIANTO[0]);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [addNewImpianto, { isLoading }] = useAddNewImpiantoMutation();

  const [loading, setLoading] = useState(false);

  const canSave =
    [impianto.nome, impianto.indirizzo].every(Boolean) &&
    addRequestStatus === "idle";

  const onSaveImpiantoClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      try {
        //console.log(impiantoToSave);

        setLoading(true);
        let form = new FormData();

        form.append("foto", impianto.foto);
        form.append("nome", impianto.nome);
        form.append("indirizzo", impianto.indirizzo);
        form.append("descrizione", impianto.descrizione);
        form.append("tipo", selectedTipo.name);
        form.append("rifiuti", impianto.rifiuti ? "1" : "0");

        let resp = await addNewImpianto(form).unwrap();

        setImpianto(emptyImpianto);
        setSelectedTipo(TIPI_IMPIANTO[0]);
        navigate("/");
      } catch (err) {
        setLoading(false);
        console.error("Failed to save new impianto", err);
      }
    }
  };

  const onInputChange = (e) => {
    setImpianto({
      ...impianto,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitchChange = (value) => {
    setImpianto({
      ...impianto,
      rifiuti: value,
    });
  };

  let content;

  content = (
    <section>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveImpiantoClicked}
        encType="multipart/form-data"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Nuovo Impianto
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Nome*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="nome"
                  value={impianto.nome}
                  setValue={onInputChange}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Descrizione
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <TextArea
                  className="max-w-lg"
                  name="descrizione"
                  value={impianto.descrizione}
                  setValue={onInputChange}
                />

                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Descrizione del tipo di impianto, dei materiali trattati e dei
                  prodotti.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Foto
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="flex items-center">
                  {impianto.foto ? (
                    <img
                      className="h-12 w-12 flex-shrink-0 rounded-full bg-gray-100"
                      src={URL.createObjectURL(impianto.foto)}
                    />
                  ) : (
                    <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}

                  <label
                    htmlFor="foto"
                    className="cursor-pointer ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <input
                      id="foto"
                      name="foto"
                      type="file"
                      className="sr-only"
                      onChange={(e) => {
                        setImpianto({
                          ...impianto,
                          foto: e.target.files[0],
                        });
                      }}
                    />
                    cambia
                  </label>
                </div>
              </div>
            </div>

            <Switch.Group
              as="div"
              className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <div className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Rifiuti
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  L'impianto accetta anche rifiuti come prodotti in ingresso.
                </Switch.Description>
              </div>
              <div>
                <Switch
                  checked={impianto.rifiuti}
                  onChange={handleSwitchChange}
                  className={classNames(
                    impianto.rifiuti ? "bg-casalucci" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      impianto.rifiuti ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </Switch.Group>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Indirizzo Impianto*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="indirizzo"
                  value={impianto.indirizzo}
                  setValue={onInputChange}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Tipo Impianto*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Select
                  className="max-w-lg"
                  selectedItem={selectedTipo}
                  setSelectedItem={setSelectedTipo}
                  items={TIPI_IMPIANTO}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to="/"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;

  return content;
};

export default NuovoImpiantoPage;
