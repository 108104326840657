import Select from "../../../components/forms/Select";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import Entrata from "../../impianti/components/Entrata";
import Uscita from "../../impianti/components/Uscita";
import useFilter from "../../../hooks/useFilter";
import { useMemo, useState } from "react";
import { filterArray } from "../../../utilities/generali";
import { NOMI_MESI } from "../../../utilities/date";
import usePagination from "../../../hooks/usePagination";
import ResocontoMovimentiModal from "./ResocontoMovimentiModal";
import WhiteButton from "../../../components/buttons/WhiteButton";

const MovimentiList = ({ movimenti }) => {
  const [modal, setModal] = useState(false);
  function sliceAnno(data) {
    return data.slice(0, 4);
  }

  function sliceMese(data) {
    const date = new Date(data);
    return NOMI_MESI[date.getMonth()];
  }
  const [anno, selectAnnoProps, clearAnno] = useFilter(
    movimenti,
    ["data"],
    "Anno",
    sliceAnno
  );

  const [mese, selectMeseProps, clearMese] = useFilter(
    movimenti,
    ["data"],
    "Mese",
    sliceMese
  );

  const movimentiToDisplay = useMemo(() => {
    let filtered = movimenti;
    if (anno) {
      filtered = filterArray(movimenti, anno, ["data"]);
    }

    if (mese) {
      filtered = filtered.filter((m) => {
        const date = new Date(m?.data);
        return NOMI_MESI.indexOf(mese) === date.getMonth();
      });
    }

    return filtered;
  }, [anno, movimenti, mese]);

  const [pagination, currentPage, maxItems] = usePagination(movimentiToDisplay);

  const clearFilters = () => {
    clearAnno();
    clearMese();
  };

  return (
    <>
      <ResocontoMovimentiModal
        open={modal}
        setOpen={setModal}
        movimenti={movimentiToDisplay}
      />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        <div className="md:col-span-4 text-md font-medium leading-6 text-gray-900">
          <h4>Filtra Per</h4>
        </div>
        <div>
          <Select {...selectAnnoProps} />
        </div>
        <div>
          <Select {...selectMeseProps} />
        </div>
        <div className="md:col-start-4 block md:ml-auto mt-auto space-x-2">
          <WhiteButton
            callback={() => setModal(true)}
            className="w-full md:w-auto"
          >
            Resoconto
          </WhiteButton>
          <PrimaryButton callback={clearFilters} className="w-full md:w-auto">
            Rimuovi Filtri
          </PrimaryButton>
        </div>
      </div>
      <div className="mt-6">
        {movimenti.length === 0 && (
          <p className="mx-auto mt-4 text-gray-700 text-center">
            Non sono presenti dei movimenti per il cliente selezionato
          </p>
        )}

        <ul
          role="list"
          className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
        >
          {movimentiToDisplay?.map(
            (movimento, index) =>
              index >= (currentPage - 1) * maxItems &&
              index < currentPage * maxItems &&
              (movimento?.type === "entrata" ? (
                <Entrata
                  entrata={movimento}
                  key={index}
                  path={`/${movimento.idImpianto}/entrate/`}
                />
              ) : (
                <Uscita
                  uscita={movimento}
                  key={index}
                  path={`/${movimento.idImpianto}/uscite/`}
                />
              ))
          )}
        </ul>
        {pagination}
      </div>
    </>
  );
};

export default MovimentiList;
