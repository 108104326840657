import {
  CalendarIcon,
  CheckCircleIcon,
  PencilIcon,
  TrashIcon,
  ExclamationTriangleIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import {
  getEntrateSelectors,
  useDeleteEntrataMutation,
} from "../entrateApiSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";

import {
  convertDate,
  formatDateObjectToItaliana,
} from "../../../utilities/date";
import EliminaModal from "../components/EliminaModal";
import NotFound from "../components/NotFound";
import { classNames, createImageLink } from "../../../utilities/generali";
import useNavigation from "../../../hooks/useNavigation";
import { selectImpiantoById } from "../impiantiApiSlice";

const EntrataPage = ({}) => {
  const { entrataId, impiantoId } = useParams();

  const [openDelete, setOpenDelete] = useState(false);
  const navigate = useNavigate();

  const { selectEntrataById } = useMemo(
    () => getEntrateSelectors(Number(impiantoId)),
    [entrataId]
  );

  const [deleteEntrata] = useDeleteEntrataMutation();

  const entrata = useSelector(selectEntrataById(Number(entrataId)));
  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([
    { name: impiantoId, newName: impianto.nome },
    { name: entrataId, newName: entrata.numero },
  ]);

  if (!entrata) return <NotFound />;

  const deleteAction = async () => {
    try {
      await deleteEntrata({ id: Number(entrataId) });
      navigate(`/${impiantoId}`);
    } catch (error) {}
  };

  const modalProps = { entrata, openDelete, setOpenDelete, deleteAction };

  let content = (
    <div className="flex-1 bg-white rounded-md py-6 px-10 max-w-6xl mx-auto">
      <div className="py-8 xl:py-10">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 xl:grid xl:max-w-full xl:grid-cols-3">
          <div className="xl:col-span-2 xl:border-r xl:border-gray-200 xl:pr-8">
            <div>
              <div>
                <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                      {entrata.fornitore
                        ? "Resoconto Entrata"
                        : "Resoconto Scarico"}
                    </h1>
                    <p className="mt-2 text-sm text-gray-500">
                      DDT n° {entrata?.numero} del{" "}
                      <span className="font-medium text-gray-900">
                        {convertDate(entrata?.data)}
                      </span>
                    </p>
                    {entrata.fornitore ? (
                      <p className="mt-2 text-sm text-gray-500">
                        Fornitore:{" "}
                        <span className="font-medium text-gray-900">
                          {entrata?.fornitore?.nome}
                        </span>
                      </p>
                    ) : (
                      <p className="mt-2 text-sm text-gray-500">
                        Cliente:{" "}
                        <span className="font-medium text-gray-900">
                          {entrata?.cliente?.nome}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="mt-4 flex space-x-3 md:mt-0">
                    <Link
                      to="edita"
                      className={classNames(
                        "inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2",
                        entrata?.protocollo &&
                          "pointer-events-none cursor-not-allowed opacity-70"
                      )}
                    >
                      <PencilIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Edita</span>
                    </Link>
                    <button
                      role="button"
                      className={classNames(
                        "inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2",
                        entrata?.protocollo &&
                          "pointer-events-none cursor-not-allowed opacity-70"
                      )}
                      onClick={() => setOpenDelete(true)}
                    >
                      <TrashIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Elimina</span>
                    </button>
                  </div>
                </div>
                <aside className="mt-8 xl:hidden">
                  <h2 className="sr-only">Dettagli</h2>
                  <div className="space-y-5">
                    <div className="space-y-5">
                      <div className="flex items-center space-x-2">
                        <TruckIcon
                          className="h-5 w-5 text-casalucci"
                          aria-hidden="true"
                        />
                        <span className="text-sm font-medium text-casalucci-600">
                          Movimento
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        {entrata?.protocollo ? (
                          <>
                            <CheckCircleIcon
                              className="h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                            <span className="text-sm font-medium text-green-700">
                              Fatturato con protocollo n° {entrata.protocollo}
                            </span>
                          </>
                        ) : (
                          <>
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-amber-600"
                              aria-hidden="true"
                            />
                            <span className="text-sm font-medium text-amber-700">
                              Non fatturato
                            </span>
                          </>
                        )}
                      </div>
                      <div className="flex items-center space-x-2">
                        <CalendarIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-700 font-medium">
                          Creato in data{" "}
                          <time dateTime={entrata?.created_at}>
                            {formatDateObjectToItaliana(entrata?.created_at)}
                          </time>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 space-y-8 border-t border-b border-gray-200 py-6">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Creato Da
                      </h2>
                      <ul role="list" className="mt-3 space-y-3">
                        <li className="flex justify-start">
                          <a href="#" className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-5 w-5 rounded-full"
                                src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900 capitalize">
                              {entrata?.createdBy?.nome}{" "}
                              {entrata?.createdBy?.cognome}
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div></div>
                  </div>
                </aside>
                <div className="py-3 xl:pb-0">
                  <div className="divide-gray-200 divide-y">
                    {entrata?.materiali?.map((risorsa) => (
                      <div key={risorsa.idRisorsa} className="py-6">
                        <div className="flex space-x-3 items-center">
                          {risorsa.risorsa.foto && (
                            <img
                              className="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
                              src={createImageLink(risorsa.risorsa.foto)}
                              alt=""
                            />
                          )}

                          <p className="font text-gray-900 font-semibold">
                            {risorsa.nome}
                          </p>
                        </div>

                        <div className="sm:flex sm:flex-row sm:gap-4 text-sm text-gray-700 font-light justify-between mt-2">
                          <div className="flex justify-between">
                            Quantità:{" "}
                            <span className="text-black font-normal ml-2">
                              {risorsa.qta} {risorsa.um !== "cad" && risorsa.um}
                            </span>
                          </div>
                          <div className="flex justify-between">
                            Importo:{" "}
                            <span className="text-black font-normal ml-2">
                              € {risorsa.importo}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="md:flex md:items-center md:justify-end md:space-x-4 xl:border-t xl:pt-6">
                  <div>
                    Totale: <span>€ {parseFloat(entrata?.importoTotale)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside className="hidden xl:block xl:pl-8">
            <h2 className="sr-only">Dettagli</h2>
            <div className="space-y-5">
              <div className="flex items-center space-x-2">
                <TruckIcon
                  className="h-5 w-5 text-casalucci"
                  aria-hidden="true"
                />
                <span className="text-sm font-medium text-casalucci-600">
                  Movimento
                </span>
              </div>
              <div className="flex items-center space-x-2">
                {entrata?.protocollo ? (
                  <>
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-green-700">
                      Fatturato con protocollo n° {entrata.protocollo}
                    </span>
                  </>
                ) : (
                  <>
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-amber-600"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-amber-700">
                      Non fatturato
                    </span>
                  </>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-700 font-medium">
                  Creato in data{" "}
                  <time dateTime={entrata?.created_at}>
                    {formatDateObjectToItaliana(entrata?.created_at)}
                  </time>
                </span>
              </div>
            </div>
            <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Creato da</h2>
                <ul role="list" className="mt-3 space-y-3">
                  <li className="flex justify-start">
                    <a href="#" className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-5 w-5 rounded-full"
                          src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="text-sm font-medium text-gray-900 capitalize">
                        {entrata?.createdBy?.nome} {entrata?.createdBy?.cognome}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <EliminaModal {...modalProps} />
      {content}
    </>
  );
};

export default EntrataPage;
