import { Link } from "react-router-dom";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";
import { formatoNumeriGrandi } from "../../../utilities/generali";

const RicetteTable = ({ ricette }) => {
  return (
    <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Nome
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Descrizione
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              UM
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Costo di Produzione
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Prezzo di Vendita
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {ricette?.map((ricetta) => (
            <tr key={ricetta.id}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                {ricetta.nome}
                <dl className="font-normal lg:hidden">
                  <dd className="mt-1 truncate text-gray-700">
                    {ricetta.descrizione}
                  </dd>

                  <dd className="mt-1 truncate text-gray-500 sm:hidden">
                    {Boolean(ricetta.costo) ? (
                      <div className="flex space-x-3 items-center">
                        <CurrencyEuroIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {`${formatoNumeriGrandi(ricetta.costo)}`}
                      </div>
                    ) : (
                      "-"
                    )}
                  </dd>
                  <dd className="mt-1 truncate text-gray-500 sm:hidden">
                    {Boolean(ricetta.prezzo) ? (
                      <div className="flex space-x-3 items-center">
                        <CurrencyEuroIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {`${formatoNumeriGrandi(ricetta.prezzo)}`}
                      </div>
                    ) : (
                      "-"
                    )}
                  </dd>
                </dl>
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {ricetta.descrizione}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {ricetta.um}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {Boolean(ricetta.costo) ? (
                  <div className="flex space-x-3 items-center">
                    <CurrencyEuroIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {`${formatoNumeriGrandi(ricetta.costo)}`}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {Boolean(ricetta.prezzo) ? (
                  <div className="flex space-x-3 items-center">
                    <CurrencyEuroIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {`${formatoNumeriGrandi(ricetta.prezzo)}`}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <Link
                  to={`${ricetta.id}`}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  Vedi
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RicetteTable;
