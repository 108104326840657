import {useSelector} from "react-redux";
import {selectAuth, selectPersist} from "../features/auth/authSlice";
import {setCredentials, logOut} from "../features/auth/authSlice";


const useAuth = () => {

    return { auth: useSelector(selectAuth), persist: useSelector(selectPersist), setCredentials, logOut }
}

export default useAuth