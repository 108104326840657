import LoginForm from "../Login";

const LoginPage = () => {
  return (
    <div className="flex min-h-full">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-row items-end space-x-4">
            <img
              className="h-12 w-auto rounded-md"
              src="/img/logo.png"
              alt="Casalucci logo"
            />
            <h2 className="text-3xl font-extrabold text-gray-900 my-auto">
              Accedi
            </h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/img/sfondo.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default LoginPage;
