import { apiSlice } from "../../app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { extendedApiSlice } from "../impianti/impiantiApiSlice";

const clientiAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.nome.localeCompare(b.nome),
});

const initialState = clientiAdapter.getInitialState();

export const clientiApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Clienti", "Movimenti"],
  endpoints: (builder) => ({
    getClienti: builder.query({
      query: () => "/clienti",
      transformResponse: (response) => {
        return clientiAdapter.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        { type: "Clienti", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Clienti",
          id,
        })),
      ],
    }),
    getMovimentiCliente: builder.query({
      query: (id) => `/impianti/movimenti/cliente/${id}`,
      providesTags: ["Movimenti"],
    }),
    addNewCliente: builder.mutation({
      query: (cliente) => ({
        url: "/clienti",
        method: "POST",
        body: cliente,
      }),
      invalidatesTags: [{ type: "Clienti", id: "LIST" }],
    }),
    updateCliente: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clienti/${id}`,
        method: "PUT",
        body: data,
      }),

      invalidatesTags: (result, error, arg) => [
        { type: "Clienti", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetClientiQuery,
  useGetMovimentiClienteQuery,
  useAddNewClienteMutation,
  useUpdateClienteMutation,
} = clientiApiSlice;

// returns the query result object
export const selectResult = extendedApiSlice.endpoints.getClienti.select();

// Creates memoized selector
const selectData = createSelector(
  selectResult,
  (result) => {
    return result.data;
  } // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllClienti,
  selectById: selectClienteById,
  // Pass in a selector that returns the posts slice of state
} = clientiAdapter.getSelectors((state) => selectData(state) ?? initialState);
