//Restituisce una data in formato italiano
export function convertDate(inputFormat, join = "-") {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  let d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(join);
}

//Restituisce una data in formato italiano senza l'anno
export function convertShortDate(inputFormat, join = "-") {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  let d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1)].join(join);
}

//RESTITUISCE UNA DATA IN STRINGA ISO
export function formatDateObjectToIso(date) {
  let dateObject = new Date(date),
    month = "" + (dateObject.getMonth() + 1),
    day = "" + dateObject.getDate(),
    year = dateObject.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

//RESTITUISCE UNA DATA IN STRINGA ITALIANA
export function formatDateObjectToItaliana(date) {
  let dateObject = new Date(date),
    month = "" + (dateObject.getMonth() + 1),
    day = "" + dateObject.getDate(),
    year = dateObject.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export const NOMI_MESI = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];
