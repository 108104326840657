import Entrata from "./Entrata";
import Select from "../../../components/forms/Select";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import useFilter from "../../../hooks/useFilter";
import { useMemo } from "react";
import { filterArray } from "../../../utilities/generali";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById } from "../impiantiApiSlice";
import usePagination from "../../../hooks/usePagination";

const EntrateList = ({ entrate = [] }) => {
  const { impiantoId } = useParams();
  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  const [fornitore, selectFornitoreProps, clearFornitore] = useFilter(
    entrate,
    ["fornitore"],
    "Fornitore"
  );

  const [cliente, selectClienteProps, clearCliente] = useFilter(
    entrate,
    ["cliente"],
    "Cliente"
  );

  function sliceAnno(data) {
    return data.slice(0, 4);
  }
  const [anno, selectAnnoProps, clearAnno] = useFilter(
    entrate,
    ["data"],
    "Anno",
    sliceAnno
  );

  const entrateToDisplay = useMemo(() => {
    let filtered = entrate;
    if (anno) {
      filtered = filterArray(entrate, anno, ["data"]);
    }

    if (cliente) {
      filtered = filtered.filter((item) =>
        item?.cliente?.nome.includes(cliente)
      );
    }

    if (fornitore) {
      filtered = filtered.filter((item) =>
        item?.fornitore?.nome.includes(fornitore)
      );
    }

    return filtered;
  }, [anno, cliente, fornitore, entrate]);

  const [pagination, currentPage, maxItems] = usePagination(entrateToDisplay);

  const clearFilters = () => {
    clearFornitore();
    clearCliente();
    clearAnno();
  };

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        <div className="md:col-span-4 text-md font-medium leading-6 text-gray-900">
          <h4>Filtra Per</h4>
        </div>
        <div>
          <Select {...selectAnnoProps} />
        </div>
        <div>
          <Select {...selectFornitoreProps} />
        </div>
        <div>
          {Boolean(impianto?.rifiuti) && <Select {...selectClienteProps} />}
        </div>
        <div className="block md:ml-auto my-auto">
          <PrimaryButton callback={clearFilters} className="w-full md:w-auto">
            Rimuovi Filtri
          </PrimaryButton>
        </div>
      </div>
      <ul
        role="list"
        className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
      >
        {entrateToDisplay.map(
          (entrata, index) =>
            index >= (currentPage - 1) * maxItems &&
            index < currentPage * maxItems && (
              <Entrata entrata={entrata} key={entrata.id} path="" />
            )
        )}
      </ul>
      {pagination}
    </div>
  );
};

export default EntrateList;
