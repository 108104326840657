import { Fragment, useCallback, useEffect, useState, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { classNames, filterArray } from "../../../utilities/generali";
import { useSelector } from "react-redux";
import {
  selectAllRisorse,
  useGetRisorseQuery,
} from "../../risorse/risorseApiSlice";
import Input from "../../../components/forms/Input";
import { useUpdateRisorseImpiantoMutation } from "../impiantiApiSlice";
import { useParams } from "react-router-dom";
import ErrorAlert from "../../../components/alerts/ErrorAlert";

const SelectRisorseImpiantoModal = ({
  open,
  setOpen,
  selectedRisorse = [],
}) => {
  const { impiantoId } = useParams();

  const [updateRisorseImpianto] = useUpdateRisorseImpiantoMutation();

  const risorseOriginali = useSelector(selectAllRisorse);

  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [risorse, setRisorse] = useState([
    ...risorseOriginali.map((item) => ({
      ...item,
      qta: selectedRisorse?.find((r) => r.risorsa.id === item.id)?.qta ?? "",
    })),
  ]);

  const [selected, setSelected] = useState([
    ...risorse.filter((r) =>
      selectedRisorse.map((s) => Number(s.risorsa.id)).includes(Number(r.id))
    ),
  ]);

  useEffect(() => {
    console.log(
      selected,
      risorse,
      selectedRisorse.map((s) => Number(s.id)).includes(45)
    );
  }, [selected]);

  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      const isIndeterminate =
        selected.length > 0 && selected.length < risorse.length;
      setChecked(selected.length === risorse.length);
      setIndeterminate(isIndeterminate);
    }
  }, [selected]);

  function toggleAll() {
    setSelected(checked || indeterminate ? [] : risorse);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const handleSave = async () => {
    try {
      await updateRisorseImpianto({
        id: impiantoId,
        risorse: [
          ...selected.map((s) => ({
            ...s,
            qta: risorse.find((r) => r.id === s.id).qta,
          })),
        ],
      }).unwrap();

      setError("");
      setOpen(false);
    } catch (e) {
      setError(e?.data?.message);
    }
  };

  const handleSearchTermChange = (e) =>
    setSearchTerm(e.target.value.toLowerCase());

  const isSelected = (risorsa) => {
    return selected.map((s) => s.id).includes(risorsa.id);
  };

  const handleIndeterminate = useCallback(
    (node) => {
      if (open && node) {
        node.indeterminate =
          selected.length > 0 && selected.length < risorse.length;
      }
    },
    [open]
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full sm:p-6 h-full">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex flex-col sm:items-start max-h-[600px]">
                  {/*Esperimento*/}

                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-xl font-semibold text-gray-900">
                        Aggiungi Risorse
                      </h1>
                      <p className="mt-2 text-sm text-gray-700">
                        Una lista di tutte le risorse presenti nel databse.
                      </p>
                    </div>
                  </div>
                  {Boolean(error) && (
                    <ErrorAlert title={error} className="w-full" />
                  )}

                  <div className="w-full sm:flex sm:items-center ml-auto mt-4">
                    <Input
                      className="w-full sm:max-w-min sm:ml-auto"
                      placeholder="Cerca"
                      value={searchTerm}
                      setValue={handleSearchTermChange}
                      name="searchTerm"
                    />
                  </div>
                  <div className="flex-grow overflow-y-auto overflow-x-hidden mt-5 ring-1 ring-black ring-opacity-5 md:rounded-lg w-full">
                    <table className="min-w-full table-fixed divide-y divide-gray-300 relative">
                      <thead className="bg-gray-50 sticky top-0 z-10">
                        <tr>
                          <th
                            scope="col"
                            className="relative w-12 px-6 sm:w-16 sm:px-8"
                          >
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                              ref={handleIndeterminate}
                              checked={checked}
                              onChange={toggleAll}
                            />
                          </th>
                          <th
                            scope="col"
                            className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Risorsa
                          </th>
                          <th
                            scope="col"
                            className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            UM
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            QTA
                          </th>
                          <th
                            scope="col"
                            className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Categoria
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {filterArray(risorse, searchTerm, [
                          "nome",
                          "categoria",
                        ]).map((risorsa) => (
                          <tr
                            key={risorsa.id}
                            className={
                              isSelected(risorsa) ? "bg-gray-50" : undefined
                            }
                          >
                            <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                              {isSelected(risorsa) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                              )}
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                value={risorsa.nome}
                                checked={isSelected(risorsa)}
                                onChange={(e) =>
                                  setSelected(
                                    e.target.checked
                                      ? [...selected, risorsa]
                                      : selected.filter(
                                          (p) => p.id !== risorsa.id
                                        )
                                  )
                                }
                              />
                            </td>
                            <td
                              className={classNames(
                                "sm:whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                isSelected(risorsa)
                                  ? "text-indigo-600"
                                  : "text-gray-900"
                              )}
                            >
                              {risorsa.nome}
                            </td>
                            <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {risorsa.um}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Input
                                className="max-w-sm"
                                name={risorsa.id}
                                setValue={(e) =>
                                  setRisorse((prev) =>
                                    prev.map((r) =>
                                      r.id === risorsa.id
                                        ? {
                                            ...r,
                                            qta: e.target.value,
                                          }
                                        : r
                                    )
                                  )
                                }
                                value={risorsa.qta}
                                disabled={!isSelected(risorsa)}
                              />
                            </td>
                            <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {risorsa.categoria}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/*Esperimento*/}
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-casalucci text-base font-medium text-white hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSave}
                  >
                    salva
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:casalucci-400 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Annulla
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SelectRisorseImpiantoModal;
