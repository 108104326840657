import {
  CircleStackIcon,
  UserIcon,
  ChevronRightIcon,
  InboxArrowDownIcon,
  TruckIcon,
  CurrencyEuroIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";
import { convertDate } from "../../../utilities/date";
import { Link } from "react-router-dom";

const Uscita = ({ uscita, path = "uscite/" }) => {
  return (
    <li>
      <Link to={`${path}${uscita.id}`} className="group block">
        <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">
              {uscita?.cliente ? (
                <CurrencyEuroIcon className="h-8 w-8 group-hover:opacity-75 text-amber-500" />
              ) : (
                <ArrowsRightLeftIcon className="h-8 w-8 group-hover:opacity-75 text-amber-500" />
              )}
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="truncate text-sm font-medium text-casalucci">
                  DDT n° <span>{uscita?.numero}</span>
                </p>
                <p className="truncate text-xs text-gray-400">
                  {uscita?.ricetta?.nome}
                </p>
                {uscita?.cliente ? (
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="truncate capitalize">
                      {uscita?.cliente?.nome}
                    </span>
                  </p>
                ) : (
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <InboxArrowDownIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="truncate capitalize">
                      {uscita?.cantiere?.nome}
                    </span>
                  </p>
                )}
              </div>
              <div className="hidden md:block">
                <div>
                  <p className="text-sm text-gray-500">
                    Data:{" "}
                    <span className="text-gray-900 font-semibold">
                      {convertDate(uscita?.data)}
                    </span>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <CircleStackIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-casalucci"
                      aria-hidden="true"
                    />
                    {uscita?.qta} {uscita?.ricetta?.um}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <TruckIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="truncate capitalize">
                      {uscita?.destinazione}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default Uscita;
