import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const impiantiAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.nome.localeCompare(a.nome),
});

const initialState = impiantiAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getImpianti: builder.query({
      query: () => "/impianti",
      transformResponse: (response) => {
        return impiantiAdapter.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        { type: "Impianti", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Impianti",
          id,
        })),
      ],
    }),
    addNewImpianto: builder.mutation({
      query: (impianto) => ({
        url: "/impianti",
        method: "POST",
        body: impianto,
      }),
      invalidatesTags: [{ type: "Impianti", id: "LIST" }],
    }),
    updateRisorseImpianto: builder.mutation({
      query: ({ id, risorse }) => ({
        url: `/impianti/${id}/risorse`,
        method: "POST",
        body: {
          risorse,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: arg.id },
      ],
    }),
    addNewRicetta: builder.mutation({
      query: (ricetta) => ({
        url: "/impianti/ricette",
        method: "POST",
        body: {
          nome: ricetta.nome,
          descrizione: ricetta.descrizione,
          um: ricetta.um,
          costo: parseFloat(ricetta.costo),
          costo_produzione: parseFloat(ricetta.costoProduzione),
          prezzo: parseFloat(ricetta.prezzo),
          id_impianto: ricetta.idImpianto,
          ingredienti: [
            ...ricetta.ingredienti.map((ingrediente) => ({
              risorsa: ingrediente.risorsa.id,
              qta: parseFloat(ingrediente.qta),
            })),
          ],
        },
      }),
      invalidatesTags: [{ type: "Impianti", id: "LIST" }],
    }),
    updateRicetta: builder.mutation({
      query: (ricetta) => ({
        url: `/impianti/ricette/${ricetta.id}`,
        method: "PUT",
        body: {
          id: ricetta.id,
          nome: ricetta.nome,
          descrizione: ricetta.descrizione,
          um: ricetta.um,
          costo: parseFloat(ricetta.costo),
          costo_produzione: parseFloat(ricetta.costoProduzione),
          prezzo: parseFloat(ricetta.prezzo),
          id_impianto: ricetta.idImpianto,
          ingredienti: [
            ...ricetta.ingredienti.map((ingrediente) => ({
              risorsa: ingrediente.risorsa.id,
              qta: parseFloat(ingrediente.qta),
            })),
          ],
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: arg.idImpianto },
      ],
    }),
    deleteRicetta: builder.mutation({
      query: (id) => ({
        url: `/impianti/ricette/${id}`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: "LIST" },
        { type: "Uscite", id: "LIST" },
      ],
    }),
    updateImpianto: builder.mutation({
      query: ({ data, id }) => ({
        url: `/impianti/${id}`,
        method: "POST",
        body: data,
        headers: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
        },
      }),

      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: arg.id },
      ],
    }),
    deleteImpianto: builder.mutation({
      query: ({ id }) => ({
        url: `/impianti/${id}`,
        method: "DELETE",
        body: { id },
      }),
    }),
  }),
});

export const {
  useGetImpiantiQuery,
  useAddNewImpiantoMutation,
  useUpdateImpiantoMutation,
  useAddNewRicettaMutation,
  useUpdateRicettaMutation,
  useUpdateRisorseImpiantoMutation,
  useDeleteRicettaMutation,
} = extendedApiSlice;

// returns the query result object
export const selectImpiantiResult =
  extendedApiSlice.endpoints.getImpianti.select();

// Creates memoized selector
const selectImpiantiData = createSelector(
  selectImpiantiResult,
  (result) => {
    return result.data;
  } // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllImpianti,
  selectById: selectImpiantoById,
  // Pass in a selector that returns the posts slice of state
} = impiantiAdapter.getSelectors(
  (state) => selectImpiantiData(state) ?? initialState
);
