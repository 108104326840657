import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById, useGetImpiantiQuery } from "../impiantiApiSlice";
import EditaEntrataForm from "../components/forms/EditaEntrataForm";
import { useMemo } from "react";
import { getEntrateSelectors, useGetEntrateQuery } from "../entrateApiSlice";
import LoadingSpinner from "../../../components/LoadingScreen/LoadingSpinner";
import useNavigation from "../../../hooks/useNavigation";
import { useGetClientiQuery } from "../../clienti/clientiApiSlice";
import EditaScaricoForm from "../components/forms/EditaScaricoForm";

const NuovaEntrataPage = () => {
  const { impiantoId, entrataId } = useParams();

  const { isLoading } = useGetEntrateQuery();
  const { isLoading: loadingClienti } = useGetClientiQuery();

  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  const { selectEntrataById } = useMemo(
    () => getEntrateSelectors(Number(impiantoId)),
    [entrataId]
  );

  const existingEntrata = useSelector(selectEntrataById(Number(entrataId)));

  useNavigation([
    { name: impiantoId, newName: impianto.nome },
    { name: entrataId, newName: existingEntrata.numero },
  ]);

  let Form = EditaEntrataForm;

  if (existingEntrata.cliente) Form = EditaScaricoForm;

  const content = (
    <div className="max-w-5xl mx-auto">
      <section className="md:flex md:items-center md:justify-start">
        <div className="py-5">
          <h3 className="text-2xl font-bold leading-6 font-medium text-gray-900">
            Edita Entrata
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {impianto?.nome}
          </p>
        </div>
      </section>

      <Form existingEntrata={existingEntrata} />
    </div>
  );
  return isLoading || loadingClienti ? <LoadingSpinner /> : content;
};

export default NuovaEntrataPage;
