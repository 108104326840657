const useRandomWidth = () => {
  const randomWidth = [16, 20, 24, 28, 32, 36];

  const width = () =>
    randomWidth[Math.floor(Math.random() * randomWidth.length)];

  return [width];
};

export default useRandomWidth;
