import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById, useGetImpiantiQuery } from "../impiantiApiSlice";
import { useMemo } from "react";
import EditaRicettaForm from "../components/forms/EditaRicettaForm";
import {
  selectAllRisorse,
  useGetRisorseQuery,
} from "../../risorse/risorseApiSlice";
import LoadingSpinner from "../../../components/LoadingScreen/LoadingSpinner";
import useNavigation from "../../../hooks/useNavigation";

const EditaRicettaPage = () => {
  const { impiantoId, ricettaId } = useParams();

  const { isLoading: loadingRisorse } = useGetRisorseQuery();
  const { isLoading: loadingImpianti } = useGetImpiantiQuery();

  const selector = useMemo(
    () => (state) => selectImpiantoById(state, Number(impiantoId)),
    [impiantoId]
  );
  const { ricette } = useSelector(selector);

  const ricetta = ricette.find(
    (ricetta) => Number(ricetta.id) === Number(ricettaId)
  );

  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([
    { name: impiantoId, newName: impianto.nome },
    { name: ricettaId, newName: ricetta.nome },
  ]);

  const risorse = useSelector(selectAllRisorse);

  const loading = loadingRisorse || loadingImpianti;

  let content = (
    <div className="max-w-5xl mx-auto">
      <section className="md:flex md:items-center md:justify-start">
        <div className="py-5">
          <h3 className="text-2xl font-bold leading-6 font-medium text-gray-900">
            Edita Ricetta
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {ricetta?.nome}
          </p>
        </div>
      </section>
      <EditaRicettaForm existingRicetta={ricetta} risorse={risorse} />
    </div>
  );

  return loading ? <LoadingSpinner /> : content;
};

export default EditaRicettaPage;
