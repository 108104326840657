import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const risorseAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.nome.localeCompare(b.nome),
});

const initialState = risorseAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRisorse: builder.query({
      query: () => "/risorse",
      transformResponse: (response) => {
        return risorseAdapter.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        { type: "Risorse", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Risorse",
          id,
        })),
      ],
    }),
    addNewRisorsa: builder.mutation({
      query: (risorsa) => ({
        url: "/risorse",
        method: "POST",
        body: risorsa,
      }),
      invalidatesTags: [{ type: "Risorse", id: "LIST" }],
    }),
    updateRisorsa: builder.mutation({
      query: (risorsa) => ({
        url: `/risorse/${risorsa.id}`,
        method: "PUT",
        body: {
          ...risorsa,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Risorse", id: arg.id },
      ],
    }),
    deleteRisorsa: builder.mutation({
      query: ({ id }) => ({
        url: `/risorse/${id}`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Risorse", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetRisorseQuery,
  useAddNewRisorsaMutation,
  useUpdateRisorsaMutation,
  useDeleteRisorsaMutation,
} = extendedApiSlice;

// returns the query result object
export const selectRisorseResult =
  extendedApiSlice.endpoints.getRisorse.select();

// Creates memoized selector
const selectRisorseData = createSelector(
  selectRisorseResult,
  (risorseResult) => risorseResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllRisorse,
  selectById: selectRisorsaById,
  // Pass in a selector that returns the posts slice of state
} = risorseAdapter.getSelectors(
  (state) => selectRisorseData(state) ?? initialState
);
