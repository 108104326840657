import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById } from "../impiantiApiSlice";
import { useMemo } from "react";
import LoadingSpinner from "../../../components/LoadingScreen/LoadingSpinner";
import { getUsciteSelectors, useGetUsciteQuery } from "../usciteApiSlice";
import EditaUscitaForm from "../components/forms/EditaUscitaForm";
import useNavigation from "../../../hooks/useNavigation";

const EditaUscitaPage = () => {
  const { impiantoId, uscitaId } = useParams();

  const { isLoading } = useGetUsciteQuery();

  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  const { selectUscitaById } = useMemo(
    () => getUsciteSelectors(Number(impiantoId)),
    [uscitaId]
  );

  const existingUscita = useSelector(selectUscitaById(Number(uscitaId)));

  useNavigation([
    { name: impiantoId, newName: impianto.nome },
    { name: uscitaId, newName: existingUscita.numero },
  ]);

  const content = (
    <div className="max-w-5xl mx-auto">
      <section className="md:flex md:items-center md:justify-start">
        <div className="py-5">
          <h3 className="text-2xl font-bold leading-6 font-medium text-gray-900">
            Edita Uscita
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {impianto?.nome}
          </p>
        </div>
      </section>

      <EditaUscitaForm existingUscita={existingUscita} />
    </div>
  );
  return isLoading ? <LoadingSpinner /> : content;
};

export default EditaUscitaPage;
