import { setCredentials, logOut, setError } from "../features/auth/authSlice";
import { useRefreshMutation } from "../features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const [refresh] = useRefreshMutation();
  const dispatch = useDispatch();
  const { auth } = useAuth();

  return async () => {
    try {
      const response = await refresh().unwrap();
      dispatch(
        setCredentials({
          role: [response?.user.role],
          access_token: response["access_token"],
          user: response?.user,
        })
      );

      return response["access_token"];
    } catch (error) {
      console.log(error);
      dispatch(setError(error?.data?.message));
    }
  };
};

export default useRefreshToken;
