import LoadingScreen from "../../../components/LoadingScreen";
import { Outlet, useParams } from "react-router-dom";
import { useGetEntrateQuery } from "../entrateApiSlice";

const EntrateLayout = () => {
  const { impiantoId } = useParams();
  const { isLoading, isSuccess, isError, error } = useGetEntrateQuery(
    Number(impiantoId)
  );

  let content;

  if (isLoading) content = <LoadingScreen />;

  if (isSuccess) content = <Outlet />;

  if (isError) content = <div>Errore: {error}</div>;

  return content;
};

export default EntrateLayout;
