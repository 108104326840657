import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import LoginPage from "./features/auth/pages/LoginPage";
import RequireAuth from "./features/auth/RequireAuth";

import PersistLogin from "./components/PersistLogin";
import SidebarLayout from "./components/SidebarLayout";
import Impianto from "./features/impianti/Impianto";
import ImpiantiLayout from "./features/impianti/ImpiantiLayout";
import ImpiantiPage from "./features/impianti/pages/ImpiantiPage";
import NuovoImpiantoPage from "./features/impianti/pages/NuovoImpiantoPage";
import AggiornaImpiantoPage from "./features/impianti/pages/AggiornaImpiantoPage";
import NuovaRicettaPage from "./features/impianti/pages/NuovaRicettaPage";
import NuovaRisorsaPage from "./features/risorse/pages/NuovaRisorsaPage";
import RisorsePage from "./features/risorse/pages/RisorsePage";
import NuovaEntrataPage from "./features/impianti/pages/NuovaEntrataPage";
import NuovaUscitaPage from "./features/impianti/pages/NuovaUscitaPage";
import EntrataPage from "./features/impianti/pages/EntrataPage";
import EntrateLayout from "./features/impianti/components/EntrateLayout";
import EditaEntrataPage from "./features/impianti/pages/EditaEntrataPage";
import UscitaPage from "./features/impianti/pages/UscitaPage";
import UsciteLayout from "./features/impianti/components/UsciteLayout";
import RicettaPage from "./features/impianti/pages/RicettaPage";
import EditaRicettaPage from "./features/impianti/pages/EditaRicettaPage";
import EditaUscitaPage from "./features/impianti/pages/EditaUscitaPage";
import EntratePage from "./features/impianti/pages/EntratePage";
import UscitePage from "./features/impianti/pages/UscitePage";
import CantieriPage from "./features/cantieri/pages/CantieriPage";
import NuovoCantierePage from "./features/cantieri/pages/NuovoCantierePage";
import EditaCantierePage from "./features/cantieri/pages/EditaCantierePage";
import CantieriLayout from "./features/cantieri/components/CantieriLayout";
import CantierePage from "./features/cantieri/pages/CantierePage";
import ClientiPage from "./features/clienti/pages/ClientiPage";
import ClientePage from "./features/clienti/pages/ClientePage";
import ClientiLayout from "./features/clienti/components/ClientiLayout";
import NuovoClientePage from "./features/clienti/pages/NuovoClientePage";
import EditaClientePage from "./features/clienti/pages/EditaClientePage";
import NuovaEntrataForm from "./features/impianti/components/forms/NuovaEntrataForm";
import NuovoScaricoForm from "./features/impianti/components/forms/NuovoScaricoForm";
import RisorseImpiantoPage from "./features/impianti/pages/RisorsePage";
import RicettePage from "./features/impianti/pages/RicettePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<LoginPage />} />

        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<SidebarLayout />}>
              {/* CANTIERI */}
              <Route path="cantieri" element={<CantieriLayout />}>
                <Route index element={<CantieriPage />} />
                <Route path=":cantiereId" element={<CantierePage />} />
                <Route path="nuovo" element={<NuovoCantierePage />} />
                <Route
                  path=":cantiereId/edita"
                  element={<EditaCantierePage />}
                />
              </Route>
              {/* CANTIERI */}

              {/* CLIENTI */}
              <Route path="clienti" element={<ClientiLayout />}>
                <Route index element={<ClientiPage />} />
                <Route path=":clienteId" element={<ClientePage />} />
                <Route path="nuovo" element={<NuovoClientePage />} />
                <Route path=":clienteId/edita" element={<EditaClientePage />} />
              </Route>
              {/* CLIENTI */}

              <Route element={<ImpiantiLayout />}>
                {/* IMPIANTI */}
                <Route index element={<ImpiantiPage />} />
                <Route path=":impiantoId" element={<Impianto />} />
                <Route
                  path=":impiantoId/edita"
                  element={<AggiornaImpiantoPage />}
                />
                <Route
                  path=":impiantoId/risorse"
                  element={<RisorseImpiantoPage />}
                />
                <Route path="nuovo" element={<NuovoImpiantoPage />} />
                {/* IMPIANTI */}

                {/* RICETTE */}
                <Route path=":impiantoId/ricette" element={<RicettePage />} />
                <Route
                  path=":impiantoId/ricette/nuova"
                  element={<NuovaRicettaPage />}
                />
                <Route
                  path=":impiantoId/ricette/:ricettaId"
                  element={<RicettaPage />}
                />
                <Route
                  path=":impiantoId/ricette/:ricettaId/edita"
                  element={<EditaRicettaPage />}
                />
                {/* RICETTE */}

                {/* ENTRATE */}
                <Route path=":impiantoId/entrate" element={<EntrateLayout />}>
                  <Route index element={<EntratePage />} />
                  <Route
                    path="nuova"
                    element={<NuovaEntrataPage Form={NuovaEntrataForm} />}
                  />
                  <Route
                    path="scarico"
                    element={<NuovaEntrataPage Form={NuovoScaricoForm} />}
                  />
                  <Route path=":entrataId" element={<EntrataPage />} />
                  <Route
                    path=":entrataId/edita"
                    element={<EditaEntrataPage />}
                  />
                </Route>
                {/* ENTRATE */}

                {/* USCITE */}
                <Route path=":impiantoId/uscite" element={<UsciteLayout />}>
                  <Route index element={<UscitePage />} />
                  <Route path="nuova" element={<NuovaUscitaPage />} />
                  <Route path=":uscitaId" element={<UscitaPage />} />
                  <Route path=":uscitaId/edita" element={<EditaUscitaPage />} />
                </Route>
                {/* USCITE */}
              </Route>

              <Route path="risorse">
                <Route index element={<RisorsePage />} />
                <Route path="nuova" element={<NuovaRisorsaPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
