import { classNames } from "../../../utilities/generali";
import { Link } from "react-router-dom";

const DesktopSidebar = ({ navigation, currentLocation }) => {
  const navItems = navigation.map((item) => {
    return {
      ...item,
      isCurrent: item.name.toLowerCase() === currentLocation.toLowerCase(),
    };
  });

  return (
    <div className="z-20 hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:bg-white lg:shadow-md">
      {/* Sidebar component, To be swapped to change style */}
      <div className="flex flex-grow flex-col overflow-y-auto pt-5 pb-4">
        <div className="flex flex-shrink-0 items-center gap-2 px-4">
          <img
            className="h-10 w-auto rounded-md"
            src="/img/logo.png"
            alt="Casalucci logo"
          />
          <h4 className="text-2xl font-semibold text-gray-700">Casalucci</h4>
        </div>
        <nav
          className="mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto"
          aria-label="Sidebar"
        >
          <div className="space-y-1 px-2">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.isCurrent
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                  "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.isCurrent
                      ? "text-gray-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "mr-3 h-6 w-6 flex-shrink-0"
                  )}
                  aria-hidden="true"
                  icon={item.fa ? item.fa : null}
                />
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default DesktopSidebar;
