import { useEffect, useState } from "react";
import DesktopSidebar from "./DesktopSidebar";
import MobileSidebar from "./MobileSidebar";
import TopBar from "./TopBar";
import Breadcrumb from "../Breadcrumb";

import { navigation } from "./navigation";
import { useLocation } from "react-router-dom";

const guest = { fullName: "Guest", initials: "G", nome: "luca" };
export default function Sidebar({ user = guest, children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const path = useLocation().pathname.split("/");
  if (path.length > 1) path.shift();

  const jsonPath = JSON.stringify(useLocation().pathname);

  useEffect(() => {
    if (sidebarOpen) setSidebarOpen(false);
  }, [jsonPath]);

  return (
    <>
      {/* Dynamic sidebar for mobile */}
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        currentLocation={path[0]}
      />

      {/* Static sidebar for desktop */}
      <DesktopSidebar navigation={navigation} currentLocation={path[0]} />

      {/* Content container */}
      <div className="flex min-h-screen flex-1 flex-col lg:pl-64">
        <TopBar user={user} setSidebarOpen={setSidebarOpen} />
        <Breadcrumb originalPath={path} />

        {/* Content */}
        {children}
      </div>
    </>
  );
}
