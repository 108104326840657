import { useDispatch, useSelector } from "react-redux";
import {
  addError,
  clearError,
  clearErrors,
  editError,
  selectErrorById,
  selectErrors,
} from "../errorsSlice";
import { useState } from "react";
import AmberWarning from "../components/AmberWarning";
import RedWarning from "../components/RedWarning";

const useError = () => {
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const error = useSelector((state) => selectErrorById(state, id));
  const errors = useSelector(selectErrors);

  const addNewError = (e) => {
    setId(e?.id);
    return dispatch(addError(e));
  };

  const alerts = (
    <div className="space-y-3">
      {errors.map((e) =>
        e?.type === "alert"
          ? e?.show && <AmberWarning {...e} />
          : e?.show && <RedWarning {...e} />
      )}
    </div>
  );

  const alert =
    error?.type === "alert"
      ? error?.show && <AmberWarning {...error} />
      : error?.show && <RedWarning {...error} />;

  return {
    errorAlert: alert,
    allAlerts: alerts,
    addError: addNewError,
    clearError: (error) => dispatch(clearError(error)),
    editError: (error) => dispatch(editError(error)),
    clearErrors: (error) => dispatch(clearErrors()),
    setCurrentError: (id) => setId(id),
    currentError: error,
  };
};

export default useError;
