import { Link } from "react-router-dom";
import RisorseList from "../RisorseList";
import { Bars4Icon } from "@heroicons/react/24/solid";
import { Squares2X2Icon } from "@heroicons/react/20/solid";

import { useState } from "react";

const RisorsePage = () => {
  const [grid, setGrid] = useState(true);

  return (
    <>
      <div className="flex-1 flex flex-col">
        <section className="min-w-full ">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">Risorse</h1>
                <p className="mt-2 text-sm text-gray-700">
                  Lista delle risorse.
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="nuova"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-casalucci px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2 sm:w-auto"
                >
                  Aggiungi Risorsa
                </Link>
              </div>
            </div>

            <div className="my-6 p-0.5 items-center flex border-b border-gray-200">
              <button
                onClick={() => setGrid(false)}
                type="button"
                className={
                  grid
                    ? "p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-casalucci-400"
                    : " bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-casalucci-400"
                }
              >
                <Bars4Icon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Use list view</span>
              </button>
              <button
                onClick={() => setGrid(true)}
                type="button"
                className={
                  !grid
                    ? "ml-0.5 p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-casalucci-400"
                    : "ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-casalucci-400"
                }
              >
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Use grid view</span>
              </button>
            </div>
            <RisorseList isGrid={grid} />
          </div>
        </section>
      </div>
    </>
  );
};

export default RisorsePage;
