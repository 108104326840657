import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const entrateAdapter = createEntityAdapter();

const initialState = entrateAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /* ENTRATE */
    getEntrate: builder.query({
      query: (id) => `/impianti/${id}/entrate`,
      transformResponse: (response) =>
        entrateAdapter.setAll(initialState, response.data),
      providesTags: (result, error, arg) => [
        { type: "Entrate", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Entrate",
          id,
        })),
      ],
    }),
    addNewEntrata: builder.mutation({
      query: (entrata) => ({
        url: "/impianti/entrate",
        method: "POST",
        body: entrataPayload(entrata),
      }),
      invalidatesTags: [
        { type: "Entrate", id: "LIST" },
        { type: "Impianti", id: "LIST" },
        "Movimenti",
      ],
    }),
    updateEntrata: builder.mutation({
      query: (entrata) => ({
        url: `/impianti/entrate/${entrata.id}`,
        method: "PUT",
        body: entrataPayload(entrata),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: arg.id_impianto },
        { type: "Entrate", id: arg.id },
        "Movimenti",
      ],
    }),
    deleteEntrata: builder.mutation({
      query: ({ id }) => ({
        url: `/impianti/entrate/${id}`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Entrate", id },
        { type: "Impianti", id: "LIST" },
        "Movimenti",
      ],
    }),
    /* ENTRATE */
  }),
});

const entrataPayload = (entrata) => {
  if (entrata.type === "scarico") {
    return {
      type: "scarico",
      id: entrata?.id,
      numero: entrata.numero,
      data: entrata.data,
      qta: entrata.qta,
      id_risorsa: entrata.risorsa.risorsa.id,
      id_risorsa_magazzino: entrata.risorsa.id,
      id_cliente: entrata.cliente.id,
      id_impianto: entrata.idImpianto,
    };
  }

  //Se l'entrata è una consegna da fornitore
  return {
    type: "consegna",
    id: entrata?.id,
    id_impianto: entrata.idImpianto,
    id_fornitore: entrata.fornitore.id,
    numero: entrata.numero,
    data: entrata.data,
    risorse: [
      ...entrata.risorse.map((risorsa) => ({
        id_risorsa: risorsa.risorsa.id,
        qta: risorsa.qta,
      })),
    ],
  };
};

export const {
  useGetEntrateQuery,
  useAddNewEntrataMutation,
  useUpdateEntrataMutation,
  useDeleteEntrataMutation,
} = extendedApiSlice;

// Define function to get selectors based on arguments (query) of getSetups
export const getEntrateSelectors = (query) => {
  const selectSetupsResult =
    extendedApiSlice.endpoints.getEntrate.select(query);

  const adapterSelectors = createSelector(selectSetupsResult, (result) =>
    entrateAdapter.getSelectors(() => result?.data ?? initialState)
  );

  return {
    selectAllEntrate: createSelector(adapterSelectors, (s) =>
      s.selectAll(undefined)
    ),
    selectEntities: createSelector(adapterSelectors, (s) =>
      s.selectEntities(undefined)
    ),
    selectIds: createSelector(adapterSelectors, (s) => s.selectIds(undefined)),
    selectTotal: createSelector(adapterSelectors, (s) =>
      s.selectTotal(undefined)
    ),
    selectEntrataById: (id) =>
      createSelector(adapterSelectors, (s) => s.selectById(s, id)),
  };
};
