import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  selectError,
  selectPersist,
  setCredentials,
  setError,
  setPersistOff,
  setPersistOn,
} from "./authSlice";
import { useLoginMutation } from "./authApiSlice";

import Input from "../../components/forms/Input";
import useAuth from "../../hooks/useAuth";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import LoadingSpinner from "../../components/LoadingScreen/LoadingSpinner";

const Login = () => {
  const userRef = React.createRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const persist = useSelector(selectPersist);
  const { auth } = useAuth();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const error = useSelector(selectError);

  useEffect(() => {
    const timeOut = setTimeout(() => dispatch(setError(null)), 20000);

    return () => clearTimeout(timeOut);
  }, [error]);

  useEffect(() => {
    localStorage.setItem("persist", persist.toString());
  }, [persist]);

  useEffect(() => {
    //console.log(auth);
    if (auth?.token) navigate(from, { replace: true });
    userRef?.current?.focus();
  }, []);

  const canSave = [user, pwd].every(Boolean);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (!canSave) {
      dispatch(setError("Tutti i campi sono obbligatori"));

      return;
    }

    try {
      const userData = await login({ username: user, password: pwd }).unwrap();

      dispatch(setCredentials({ ...userData }));
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
      dispatch(setError(null));
    } catch (err) {
      if (!err?.status) {
        dispatch(setError("Nessuna risposta dal server"));
      } else if (err.status === 400) {
        dispatch(setError("Tutti i campi sono obbligatori"));
      } else if (err.status === 403) {
        dispatch(setError("Nome utente o password errati"));
      } else {
        dispatch(setError("Tentativo di login fallito"));
      }
    }
  };

  const handleUserInput = (e) => setUser(e.target.value);

  const handlePwdInput = (e) => setPwd(e.target.value);

  const handleTogglePersist = () => {
    if (persist) {
      dispatch(setPersistOff());
    } else {
      dispatch(setPersistOn());
    }
  };

  return isLoading ? (
    <div className="flex flex-col justify-center items-center h-40">
      <LoadingSpinner />
    </div>
  ) : (
    <section>
      {error && <ErrorAlert title={error} />}

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <Input
            value={user}
            setValue={handleUserInput}
            label="Nome Utente"
            name="username"
            autoComplete="off"
            ref={userRef}
            required={submitted}
          />
        </div>

        <div className="space-y-1">
          <Input
            value={pwd}
            setValue={handlePwdInput}
            label="Password"
            name="password"
            autoComplete="current-password"
            type="password"
            required={submitted}
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="persist"
              name="persist"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onChange={handleTogglePersist}
              checked={persist}
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Ricordati di me
            </label>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div className="text-sm">
            <a
              href="#"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Dimenticato la password?
            </a>
          </div>
        </div>
        <PrimaryButton type="submit" className="w-full">
          Sign in
        </PrimaryButton>
      </form>
    </section>
  );
};

export default Login;
