import useRandomWidth from "../../../../hooks/useRandomWitsh";

const ImpiantoSkeleton = () => {
  const [width] = useRandomWidth();

  return (
    <div className="flex-1 min-w-0">
      <div className={`h-5 bg-slate-200 rounded w-56`} />
      <div className="flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
        <div className="mt-2 flex items-center text-sm text-gray-500">
          {/* ICON */}
          <div className="rounded-full bg-slate-200 h-5 w-5 mr-1.5" />
          {/* ICON */}
          <div className={`h-2 bg-slate-200 rounded w-${width()}`} />
        </div>
        <div className="mt-2 flex items-center text-sm text-gray-500">
          {/* ICON */}
          <div className="rounded-full bg-slate-200 h-5 w-5 mr-1.5" />
          {/* ICON */}
          <div className={`h-2 bg-slate-200 rounded w-${width()}`} />
        </div>
      </div>
    </div>
  );
};

export default ImpiantoSkeleton;
