import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SearchBox from "../../cantieri/components/SearchBox";
import ClientiTable from "../../clienti/components/ClientiTable";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById } from "../impiantiApiSlice";
import { useMemo, useState } from "react";
import { filterArray, sortByProp } from "../../../utilities/generali";
import RicetteTable from "../components/RicetteTable";

const RicettePage = () => {
  const { impiantoId } = useParams();
  const navigate = useNavigate();

  const { nome: impianto, ricette } = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  const [searchTerm, setSearchTerm] = useState("");

  //Mostra solo i clienti che corrispondono al termine di ricerca e che sono attivi
  const ricetteToDisplay = useMemo(() => {
    console.log(ricette);
    return sortByProp(filterArray(ricette, searchTerm, ["nome"]), "nome");
  }, [searchTerm, ricette]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Ricette impianto {impianto}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Lista ricette impianto {impianto}.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <PrimaryButton onClick={() => navigate("nuova")}>
            Aggiungi Riccetta
          </PrimaryButton>
        </div>
      </div>
      {/* TABELLA DEI CLIENTI */}
      <div className="h-full">
        <div className="sm:flex sm:justify-end mb-2 mt-6">
          <div>
            <SearchBox
              searchTerm={searchTerm}
              handleChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
        </div>

        <RicetteTable ricette={ricetteToDisplay} />
      </div>
    </div>
  );
};

export default RicettePage;
