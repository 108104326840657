import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../features/auth/authApiSlice";
import { logOut } from "../features/auth/authSlice";

const useLogout = () => {
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();

  return async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(logOut());
    }
  };
};

export default useLogout;
