import { apiSlice } from "../../app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { extendedApiSlice } from "../impianti/impiantiApiSlice";
import { sortByProp } from "../../utilities/generali";

const fornitoriAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.nome.localeCompare(b.nome),
});

const initialState = fornitoriAdapter.getInitialState();

export const fornitoriApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFornitori: builder.query({
      query: () => "/fornitori",
      transformResponse: (response) => {
        return {
          ...fornitoriAdapter.setAll(initialState, response?.data),
          attivi: sortByProp(
            response?.data?.filter((f) => Number(f.stato) === 1),
            "nome"
          ),
        };
      },
      providesTags: (result, error, arg) => [
        { type: "Fornitori", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Fornitori",
          id,
        })),
      ],
    }),
    getEntrateFornitore: builder.query({
      query: (id) => `/impianti/entrate/fornitore/${id}`,
    }),
    addNewFornitore: builder.mutation({
      query: (cliente) => ({
        url: "/fornitori",
        method: "POST",
        body: cliente,
      }),
      invalidatesTags: [{ type: "Fornitori", id: "LIST" }],
    }),
    updateFornitore: builder.mutation({
      query: ({ data, id }) => ({
        url: `/fornitori/${id}`,
        method: "PUT",
        body: data,
      }),

      invalidatesTags: (result, error, arg) => [
        { type: "Fornitori", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetFornitoriQuery,
  useGetEntrateFornitoreQuery,
  useAddNewFornitoreMutation,
  useUpdateFornitoreMutation,
} = fornitoriApiSlice;

// returns the query result object
export const selectResult = extendedApiSlice.endpoints.getFornitori.select();

// Creates memoized selector
const selectData = createSelector(
  selectResult,
  (result) => {
    return result.data;
  } // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllFornitori,
  selectById: selectFornitoreById,
  // Pass in a selector that returns the posts slice of state
} = fornitoriAdapter.getSelectors((state) => selectData(state) ?? initialState);

export const selectFornitoriAttivi = createSelector(
  selectResult,
  (data) => data?.data?.attivi ?? []
);
