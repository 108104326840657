import { useId, useState } from "react";
import { useUpdateEntrataMutation } from "../../entrateApiSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../../../components/forms/Input";
import Select from "../../../../components/forms/Select";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AggiungiRisorseModal from "../../AggiungiRisorseModal";
import LoadingScreen from "../../../../components/LoadingScreen";
import useError from "../../../errors/hooks/useError";
import { useSelector } from "react-redux";
import {
  selectAllFornitori,
  useGetFornitoriQuery,
} from "../../../fornitori/fornitoriApiSlice";

const EditaEntrataForm = ({ existingEntrata }) => {
  const { impiantoId, entrataId } = useParams();

  const [updateEntrata] = useUpdateEntrataMutation();
  useGetFornitoriQuery();
  const fornitori = useSelector(selectAllFornitori)?.map((fornitore) => ({
    ...fornitore,
    name: fornitore.nome,
  }));

  const navigate = useNavigate();
  const errorId = useId();
  const { addError, clearErrors, allAlerts, clearError } = useError();

  const emptyEntrata = {
    id: existingEntrata.id,
    numero: existingEntrata?.numero,
    data: existingEntrata?.data,
    idImpianto: parseInt(impiantoId),
    fornitore: null,
  };

  const [entrata, setEntrata] = useState(emptyEntrata);
  const [open, setOpen] = useState(false);
  const [risorse, setRisorse] = useState([
    ...existingEntrata?.materiali.map((materiale) => ({
      risorsa: materiale.risorsa,
      qta: materiale.qta,
      id: materiale.idRisorsa,
    })),
  ]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    setEntrata({
      ...entrata,
      [e.target.name]: e.target.value,
    });
  };

  const canSave =
    [entrata.numero, entrata.data, entrata.fornitore].every(Boolean) &&
    risorse.length > 0 &&
    risorse.every((risorsa) => risorsa.qta !== "");

  const onSaveEntrataClicked = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    if (canSave) {
      try {
        setLoading(true);
        clearError("422");
        clearError(errorId);
        let { data } = await updateEntrata({
          ...entrata,
          risorse: [...risorse],
        }).unwrap();

        setEntrata(emptyEntrata);
        clearErrors();
        navigate(`/${impiantoId}/entrate/${data.id}`);
      } catch (err) {
        setLoading(false);
        addError({
          id: errorId,
          type: "Error",
          title: err?.data?.message,
          show: true,
        });
      }
    }
  };

  const onRisorsaChange = (e) => {
    setRisorse((prev) =>
      prev.map((obj) => {
        if (obj.id.toString() === e.target.name) {
          let newValue = e.target.value
            .replace(/,/g, ".")
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");

          return {
            ...obj,
            qta: newValue,
          };
        }

        return obj;
      })
    );
  };

  const handleRisorsaRemove = (id) => {
    setRisorse((prev) =>
      prev.filter((obj) => {
        return obj.id !== id;
      })
    );
  };

  const handleAddRisorse = (newRisorse) => {
    setRisorse((prev) => [
      ...prev.filter((risorsa) => {
        if (newRisorse.includes(risorsa?.risorsa)) return risorsa;
      }),
      ...newRisorse
        .filter((risorsa) => !risorse.some((obj) => obj.id === risorsa.id))
        .map((risorsa) => ({
          risorsa: risorsa,
          qta: "",
          id: risorsa.id,
        })),
    ]);
  };

  const handleChangeFornitore = (fornitore) => {
    setEntrata({
      ...entrata,
      fornitore: fornitore,
    });
  };

  const selectedFornitore = () => {
    if (!entrata.fornitore && fornitori.length > 0) {
      setEntrata({
        ...entrata,
        fornitore: fornitori.find(
          (fornitore) => fornitore.id === existingEntrata?.fornitore.id
        ),
      });
    }

    return entrata.fornitore;
  };

  let content;

  content = (
    <section>
      <div className="mb-4">{allAlerts}</div>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveEntrataClicked}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Numero*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="numero"
                  value={entrata.numero}
                  setValue={onInputChange}
                  errorMessage="Inserire un numero di DDT"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Numero del documento di trasporto associato all'entrata nel
                  magazzino dell'impianto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Data*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="data"
                  type="date"
                  value={entrata.data}
                  setValue={onInputChange}
                  errorMessage="Inserire una data per il movimento"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Data della consegna del materiale.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Fornitore*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Select
                  className="max-w-lg"
                  name="fornitore"
                  items={fornitori}
                  selectedItem={selectedFornitore()}
                  setSelectedItem={handleChangeFornitore}
                  errorMessage="Inserire un fornitore per il movimento"
                  required={submitted}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Risorse*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-3xl">
                {risorse.length === 0 && submitted && (
                  <p className="mt-2 text-red-500 text-sm">
                    Seleziona almeno una risorsa per salvare
                  </p>
                )}
                {risorse.length > 0 && (
                  <div className="bg-white shadow-sm overflow-hidden sm:rounded-md sm:border sm:border-gray-300">
                    <ul role="list" className="divide-y divide-gray-200">
                      {risorse.map((ingredient) => (
                        <li key={ingredient?.id}>
                          <div className="sm:grid sm:gap-20 sm:grid-cols-2 items-center justify-between px-2 py-4 sm:px-4">
                            <div className="flex items-center gap-4">
                              <button
                                onClick={() =>
                                  handleRisorsaRemove(ingredient?.id)
                                }
                                type="button"
                                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-red-600 border-red-600 border-2  hover:border-red-700 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              >
                                <XMarkIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              </button>
                              <p>{ingredient?.risorsa?.nome}</p>
                            </div>

                            <div className="flex items-center justify-between">
                              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                                {ingredient?.risorsa?.um}
                              </span>
                              <div>
                                <Input
                                  placeholder="Quantità"
                                  className="max-w-xs ml-auto shadow-none"
                                  name={ingredient?.id}
                                  value={ingredient?.qta}
                                  setValue={onRisorsaChange}
                                  errorMessage="Inserire la quantità"
                                  required={submitted}
                                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="flex justify-end mt-2">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
                  >
                    Aggiungi Risorse
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to={`//${impiantoId}/entrate/${entrataId}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annulla
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Salva
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;
  return (
    <>
      <AggiungiRisorseModal
        open={open}
        setOpen={setOpen}
        setNewRisorse={handleAddRisorse}
        selectedRisorse={risorse}
      />
      {content}
    </>
  );
};

export default EditaEntrataForm;
