import { classNames } from "../../utilities/generali";
import React from "react";

const Input = React.forwardRef(
  (
    {
      label,
      name,
      autoComplete = "",
      value,
      setValue,
      type = "text",
      setValidity,
      isValid = true,
      className,
      errorMessage = "",
      ...others
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <input
          type={type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          className={classNames(
            isValid
              ? "border border-gray-300 focus:border-casalucci focus:ring-casalucci"
              : "border-2 border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500",
            "peer mt-1 block h-9  w-full cursor-text rounded-md shadow-sm disabled:cursor-not-allowed disabled:bg-gray-200 disabled:bg-opacity-80 sm:text-sm invalid:border-red-500 invalid:border-2 invalid:ring-red-500 invalid:focus:border-red-500 invalid:focus:ring-red-500",
            className
          )}
          value={value}
          onChange={setValue}
          ref={ref}
          {...others}
        />
        {errorMessage && (
          <p className="mt-2 hidden peer-invalid:block text-red-500 text-sm">
            {errorMessage}
          </p>
        )}
      </>
    );
  }
);

export default Input;
