import { Link } from "react-router-dom";
import {
  ChevronRightIcon,
  CurrencyEuroIcon,
} from "@heroicons/react/24/outline";

const RicetteList = ({ ricette }) => {
  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
        Ricette
      </h2>

      {/* RICETTE */}
      <div className="mt-6 flow-root">
        <ul
          role="list"
          className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
        >
          {ricette.map((ricetta) => (
            <li key={ricetta.id}>
              <Link to={`ricette/${ricetta.id}`} className="group block">
                <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                  <div className="min-w-0 flex-1 px-4">
                    <p className="truncate text-sm font-medium text-casalucci">
                      {ricetta.nome}
                    </p>
                    <div className="flex justify-between items-center mt-2">
                      <p>Costo di produzione</p>

                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyEuroIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="truncate">{ricetta.costo}</span>
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <p>Prezzo di vendita</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyEuroIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="truncate">{ricetta.prezzo}</span>
                      </p>
                    </div>
                  </div>

                  <div>
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RicetteList;
