export const CATEGORIE_RISORSA = [
  "Altro",
  "Acqua",
  "Tubi PVC",
  "Tubi CLS",
  "Tubi GRES",
  "Tubi PEAD",
  "Tubi Acciaio",
  "Materiale Idraulico PEAD",
  "Materiale Idraulico Acciaio",
  "Materiale Idraulico PVC",
  "Manufatti CLS",
  "Autobloccanti",
  "Manufatti Lapidei",
  "Conglomerati Bituminosi",
  "Prodotti di Cava",
  "Chiusini Ghisa",
  "Acciaio",
  "Legno",
  "Cementi",
  "Calcestruzzi",
  "Malte",
  "Laterizi",
  "Altri Leganti",
  "Altri Lapidei",
  "Altri Ceramici",
  "Rifiuti",
];
