import Pagination from "../features/risorse/Pagination";
import { useEffect, useState } from "react";
import Select from "../components/forms/Select";

const MAX_ITEMS_OPTIONS = [
  {
    id: 1,
    nome: 3,
  },
  {
    id: 2,
    nome: 5,
  },
  {
    id: 3,
    nome: 10,
  },
  {
    id: 4,
    nome: 15,
  },
  {
    id: 5,
    nome: 20,
  },
  {
    id: 6,
    nome: 50,
  },
  {
    id: 7,
    nome: 100,
  },
];

const usePagination = (items) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [max, setMax] = useState(MAX_ITEMS_OPTIONS[1]);

  useEffect(() => setCurrentPage(1), [items]);

  const selectMaxItems = (
    <Select
      items={MAX_ITEMS_OPTIONS}
      label="Elementi per Pagina"
      selectedItem={max}
      setSelectedItem={setMax}
    />
  );

  const pagination = (
    <Pagination
      itemsNumber={items.length}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      maxItems={max.nome}
    />
  );
  return [pagination, currentPage, max.nome, selectMaxItems];
};

export default usePagination;
