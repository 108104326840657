import { XCircleIcon } from "@heroicons/react/20/solid";

const RedWarning = ({ title = "", body = null }) => {
  return (
    <div className="rounded-md bg-red-50 p-4 shadow ring-1 ring-black ring-opacity-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {body && (
            <div className="mt-2 text-sm text-red-700">
              <p>{body}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RedWarning;
