import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditaClienteForm from "../components/EditaClienteForm";
import { selectClienteById } from "../clientiApiSlice";
import useNavigation from "../../../hooks/useNavigation";
import { useEffect } from "react";
import { setPathChanges } from "../../auth/navigationSlice";

const EditaClientePage = () => {
  const { clienteId } = useParams();

  const cliente = useSelector((state) =>
    selectClienteById(state, Number(clienteId))
  );

  useNavigation([{ name: clienteId, newName: cliente.nome }]);

  const clienteToEdit = {
    id: cliente.id,
    nome: cliente.nome,
    indirizzo: cliente.indirizzo,
    mail: cliente.mail,
    telefono: cliente.telefono,
    pec: cliente.pec,
    cf: cliente.cf,
    cu: cliente.cu,
    pi: cliente.pi,
  };

  return cliente && <EditaClienteForm existingCliente={clienteToEdit} />;
};

export default EditaClientePage;
