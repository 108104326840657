import Input from "../../../components/forms/Input";

const SearchBox = ({ searchTerm = "", handleChange }) => {
  return (
    <Input
      placeholder="Cerca"
      value={searchTerm}
      setValue={handleChange}
      name="searchTerm"
      label="Cerca"
    />
  );
};

export default SearchBox;
