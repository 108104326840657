import Select from "../../../components/forms/Select";
import {
  selectAllCantieri,
  useGetCantieriQuery,
} from "../../cantieri/cantieriApiSlice";
import { useSelector } from "react-redux";

const SelectCantiere = ({ selectedCantiere, handleChange, submitted }) => {
  const {} = useGetCantieriQuery();

  const cantieri = useSelector(selectAllCantieri);

  const valid = () => {
    if (submitted)
      return selectedCantiere && Object.keys(selectedCantiere).length !== 0;

    return true;
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Cantiere*
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:max-w-xs">
        <Select
          name="cantiere"
          items={cantieri}
          selectedItem={selectedCantiere}
          setSelectedItem={handleChange}
          errorMessage="Inserire un cantiere per il movimento"
          isValid={valid()}
        />
      </div>
    </div>
  );
};

export default SelectCantiere;
