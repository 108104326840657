import { selectImpiantoById } from "../impiantiApiSlice";
import { useSelector } from "react-redux";
import Select from "../../../components/forms/Select";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const SelectRicetta = ({ selectedRicetta, handleChange, submitted }) => {
  const { impiantoId } = useParams();

  const selector = useMemo(
    () => (state) => selectImpiantoById(state, Number(impiantoId)),
    [impiantoId]
  );

  const { ricette } = useSelector(selector);

  const valid = () => {
    if (submitted)
      return selectedRicetta && Object.keys(selectedRicetta).length !== 0;

    return true;
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Ricetta*
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:max-w-xs">
        <Select
          name="ricetta"
          items={ricette}
          selectedItem={selectedRicetta}
          setSelectedItem={handleChange}
          errorMessage="Inserire una ricetta per il movimento"
          isValid={valid()}
        />
      </div>
    </div>
  );
};

export default SelectRicetta;
