import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faHome,
  faSnowplow,
  faUsers,
  faSeedling,
  faFileInvoiceDollar,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons";

export const navigation = [
  {
    name: "Home",
    href: "/",
    icon: FontAwesomeIcon,
    fa: faHome,
  },
  {
    name: "Cantieri",
    href: "/cantieri",
    icon: FontAwesomeIcon,
    fa: faSnowplow,
  },
  {
    name: "Clienti",
    href: "/clienti",
    icon: FontAwesomeIcon,
    fa: faUsers,
  },
  {
    name: "Risorse",
    href: "/risorse",
    icon: FontAwesomeIcon,
    fa: faSeedling,
  },
];
