import { XCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utilities/generali";

const ErrorAlert = ({
  title = "",
  children = null,
  className = "",
  ...others
}) => {
  return (
    <div
      className={classNames("rounded-md bg-red-100 p-4 my-3", className)}
      {...others}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {children ? (
            <div className="mt-2 text-sm text-red-700">{children}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ErrorAlert;
