import Sidebar from "./navigation/Sidebar";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const SidebarLayout = () => {
  const { auth } = useAuth();

  return (
    <>
      {
        <Sidebar user={auth.user}>
          <main>
            <div className="py-6">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <Outlet />
              </div>
            </div>
          </main>

          <span className="bg-opacity-45 mt-auto h-12 w-full bg-gray-200 py-4 px-4 text-center mt-5">
            © 2021 Casalucci s.r.l. - tutti i diritti riservati
          </span>
        </Sidebar>
      }
    </>
  );
};

export default SidebarLayout;
