import axios from "axios";
import { API_PATH } from "../constants/paths";

export default axios.create({
  baseURL: API_PATH,
});

export const axiosPrivate = axios.create({
  baseURL: API_PATH,
  headers: {
    "Content-Type": "Application/json",
  },
  withCredentials: true,
});

export const axiosPrivateWithToken = (token) =>
  axios.create({
    baseURL: API_PATH,
    headers: {
      "Content-Type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
