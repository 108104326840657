import { useNavigate } from "react-router-dom";
import CantieriTable from "../components/CantieriTable";
import {
  selectAllCantieri,
  selectCantieriAperti,
  useGetCantieriQuery,
} from "../cantieriApiSlice";
import { useSelector } from "react-redux";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { selectCurrentUser } from "../../auth/authSlice";

const CantieriPage = () => {
  const navigate = useNavigate();

  const { isLoading } = useGetCantieriQuery();
  const cantieri = useSelector(selectCantieriAperti);
  const user = useSelector(selectCurrentUser);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Cantieri</h1>
          <p className="mt-2 text-sm text-gray-700">
            Lista dei cantieri attivi.
          </p>
        </div>
        {user?.role === 104 ? (
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <PrimaryButton onClick={() => navigate("nuovo")}>
              Aggiungi Cantiere
            </PrimaryButton>
          </div>
        ) : undefined}
      </div>
      {/* TABELLA DEI CANTIERI */}

      {isLoading ? null : <CantieriTable cantieri={cantieri} />}
    </div>
  );
};

export default CantieriPage;
