import Input from "../../../components/forms/Input";
import { Switch } from "@headlessui/react";
import TextArea from "../../../components/forms/TextArea";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddNewRisorsaMutation } from "../risorseApiSlice";
import EuroInput from "../../../components/forms/EuroInput";
import Select from "../../../components/forms/Select";
import { CATEGORIE_RISORSA } from "../constants";
import ErrorAlert from "../../../components/alerts/ErrorAlert";
import { classNames } from "../../../utilities/generali";
import LoadingScreen from "../../../components/LoadingScreen";

const NuovaRisorsaPage = () => {
  const navigate = useNavigate();

  const emptyRisorsa = {
    nome: "",
    categoria: "",
    note: "",
    costo: "",
    um: "",
    foto: null,
    cer: "",
  };

  const [risorsa, setRisorsa] = useState(emptyRisorsa);
  const [illimitata, setIllimitata] = useState(false);
  const [rifiuto, setRifiuto] = useState(false);
  const [error, setError] = useState({
    show: false,
    body: "",
  });

  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [addNewRisorsa, { isLoading }] = useAddNewRisorsaMutation();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const canSave =
    [risorsa.nome, risorsa.costo, risorsa.um, risorsa.categoria].every(
      Boolean
    ) && addRequestStatus === "idle";

  const categorie = CATEGORIE_RISORSA.sort().map((categoria, index) => ({
    id: index,
    name: categoria,
  }));

  const onSaveRisorsaClicked = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (canSave) {
      let form = new FormData();

      form.append("foto", risorsa.foto);
      form.append("nome", risorsa.nome);
      form.append("categoria", risorsa?.categoria?.name);
      form.append("note", risorsa.note);
      form.append("costo", risorsa.costo);
      form.append("um", risorsa.um);
      form.append("illimitata", illimitata.toString());
      form.append("rifiuto", rifiuto.toString());
      form.append("cer", risorsa?.cer ?? null);

      setError({
        show: false,
        body: "",
      });

      try {
        //console.log(risorsaToSave);
        setLoading(true);
        await addNewRisorsa(form).unwrap();
        setRisorsa(emptyRisorsa);
        navigate("/risorse");
      } catch (err) {
        setLoading(false);
        setError({
          show: true,
          body: err?.data?.message,
        });
      }
    } else {
      setError({
        show: true,
        body: "Compila i campi obbligatori per poter salvare",
      });
    }
  };

  const onInputChange = (e) => {
    setRisorsa({
      ...risorsa,
      [e.target.name]: e.target.value,
    });
  };

  const onCategoriaChange = (newCategoria) => {
    setRisorsa({
      ...risorsa,
      categoria: newCategoria,
    });
  };

  let content;

  content = (
    <section className="">
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveRisorsaClicked}
      >
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 sm:p-6 bg-white space-y-8 divide-y divide-gray-200 sm:space-y-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Nuova Risorsa
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                I campi indicati con * sono obbligatori.
              </p>
              {error.show ? <ErrorAlert title={error.body} /> : null}
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="nome"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Nome*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="nome"
                    value={risorsa.nome}
                    setValue={onInputChange}
                    required={submitted}
                    errorMessage="Inserisci un nome per la risorsa"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Categoria*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    name="categoria"
                    selectedItem={risorsa.categoria}
                    setSelectedItem={onCategoriaChange}
                    items={categorie}
                    className="max-w-lg"
                    required={submitted}
                    errorMessage="Seleziona una categoria"
                    isValid={[risorsa.categoria].every(Boolean) || !submitted}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="note"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Note
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <TextArea
                    className="max-w-lg"
                    name="note"
                    value={risorsa.note}
                    setValue={onInputChange}
                  />

                  <p className="mt-2 text-sm text-gray-500 max-w-lg">
                    Eventuali note sulla risorsa.
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Foto
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex items-center">
                    {risorsa.foto ? (
                      <img
                        className="h-12 w-12 flex-shrink-0 rounded-full bg-gray-100"
                        src={URL.createObjectURL(risorsa.foto)}
                      />
                    ) : (
                      <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}

                    <label
                      htmlFor="foto"
                      className="cursor-pointer ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <input
                        id="foto"
                        name="foto"
                        type="file"
                        className="sr-only"
                        onChange={(e) => {
                          setRisorsa({
                            ...risorsa,
                            foto: e.target.files[0],
                          });
                        }}
                      />
                      cambia
                    </label>
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="costo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Costo*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                  <EuroInput
                    name="costo"
                    value={risorsa.costo}
                    setValue={onInputChange}
                    required={submitted}
                    errorMessage="Il costo della produzione è obbligatorio e deve essere un numero"
                    pattern="[+-]?([0-9]*[.])?[0-9]+"
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Costo della risorsa.
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="um"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Unità di misura*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-xs"
                    name="um"
                    value={risorsa.um}
                    setValue={onInputChange}
                    required={submitted}
                    errorMessage="Inserisci una unità di misura"
                  />
                </div>
              </div>

              <Switch.Group as="div">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:items-center">
                  <div>
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Illimitata
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        La risorsa è presente senza un limite di quantità nei
                        magazzini, per esempio l'acqua.
                      </Switch.Description>
                    </span>
                  </div>

                  <div className="mt-4 sm:col-span-2 sm:mt-0">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <Switch
                            checked={illimitata}
                            onChange={setIllimitata}
                            className={classNames(
                              illimitata ? "bg-casalucci" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                illimitata ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Switch.Group>
              <Switch.Group as="div">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Rifiuto
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-gray-500"
                      >
                        La risorsa è un rifiuto.
                      </Switch.Description>
                    </span>
                  </div>

                  <div className="mt-4 sm:col-span-2 sm:mt-0">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <Switch
                            checked={rifiuto}
                            onChange={setRifiuto}
                            className={classNames(
                              rifiuto ? "bg-casalucci" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                rifiuto ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Switch.Group>
              {rifiuto && (
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="um"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Codice CER
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Input
                      className="max-w-xs"
                      name="cer"
                      value={risorsa.cer}
                      setValue={onInputChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">
            <Link
              to="/risorse"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;

  return content;
};

export default NuovaRisorsaPage;
