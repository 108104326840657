import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import cantieriReducer from "../features/cantieri/cantieriSlice";
import navigationReducer from "../features/auth/navigationSlice";
import errorReducer from "../features/errors/errorsSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    navigation: navigationReducer,
    cantieri: cantieriReducer,
    error: errorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
