import { useParams } from "react-router-dom";
import { selectCantiereById } from "../cantieriApiSlice";
import { useSelector } from "react-redux";
import EditaCantiereForm from "../components/EditaCantiereForm";
import useNavigation from "../../../hooks/useNavigation";

const EditaCantierePage = () => {
  const { cantiereId } = useParams();

  const cantiere = useSelector((state) =>
    selectCantiereById(state, Number(cantiereId))
  );

  useNavigation([{ name: cantiereId, newName: cantiere.nome }]);

  const cantiereToEdit = {
    id: cantiere.id,
    nome: cantiere.nome,
    indirizzo: cantiere.indirizzo,
    descrizione: cantiere.descrizione,
    importo: cantiere.importo,
    pubblico: Boolean(Number(cantiere.pubblico)),
  };

  return cantiere && <EditaCantiereForm existingCantiere={cantiereToEdit} />;
};

export default EditaCantierePage;
