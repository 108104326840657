import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { filterArray } from "../../../utilities/generali";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SearchBox from "../../cantieri/components/SearchBox";
import { selectAllClienti } from "../clientiApiSlice";
import ClientiTable from "../components/ClientiTable";

const ClientiPage = ({}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const clienti = useSelector(selectAllClienti);

  //Mostra solo i clienti che corrispondono al termine di ricerca e che sono attivi
  const clientiToDisplay = useMemo(() => {
    return filterArray(clienti, searchTerm, [
      "nome",
      "indirizzo",
      "mail",
      "telefono",
      "cf",
      "pi",
    ]).filter((cantiere) => Number(cantiere.stato) === 1);
  }, [searchTerm, clienti]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Clienti</h1>
          <p className="mt-2 text-sm text-gray-700">
            Lista dei clienti attivi.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <PrimaryButton onClick={() => navigate("nuovo")}>
            Aggiungi Cliente
          </PrimaryButton>
        </div>
      </div>
      {/* TABELLA DEI CLIENTI */}
      <div className="h-full">
        <div className="sm:flex sm:justify-end mt-6">
          <div>
            <SearchBox
              searchTerm={searchTerm}
              handleChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
        </div>

        <ClientiTable clienti={clientiToDisplay} />
      </div>
    </div>
  );
};

export default ClientiPage;
