import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    error: null,
    persist: JSON.parse(localStorage.getItem("persist")) || false,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, access_token } = action.payload;
      state.user = user;
      state.token = access_token;
      state.roles = user.role;
    },
    setNewToken: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
    },
    setPersistOn: (state) => {
      state.persist = true;
    },
    setPersistOff: (state) => {
      state.persist = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    removeError: (state, action) => {
      state.error = null;
    },
  },
});

export const {
  setCredentials,
  logOut,
  setPersistOn,
  setPersistOff,
  setNewToken,
  setError,
  removeError,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectAuth = (state) => state.auth;
export const selectError = (state) => state.auth.error;
export const selectPersist = (state) => state.auth.persist;
