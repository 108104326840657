import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const usciteAdapter = createEntityAdapter();

const initialState = usciteAdapter.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUscite: builder.query({
      query: (id) => `/impianti/${id}/uscite`,
      transformResponse: (response) =>
        usciteAdapter.setAll(initialState, response.data),
      providesTags: (result, error, arg) => [
        { type: "Uscite", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Uscite",
          id,
        })),
      ],
    }),
    deleteUscita: builder.mutation({
      query: ({ id }) => ({
        url: `/impianti/uscite/${id}`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Uscite", id },
        { type: "Impianti", id: "LIST" },
        "Movimenti",
      ],
    }),
    addNewUscita: builder.mutation({
      query: (uscita) => ({
        url: "/impianti/uscite/nuova",
        method: "POST",
        body: {
          id_impianto: uscita.idImpianto,
          id_ricetta: Number(uscita.ricetta.id),
          id_cliente: uscita.cliente?.id ? Number(uscita.cliente?.id) : null,
          id_cantiere: uscita.cantiere?.id ? Number(uscita.cantiere?.id) : null,
          numero: uscita.numero,
          destinazione: uscita.destinazione,
          data: uscita.data,
          qta: uscita.qta,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: arg.id_impianto },
        "Uscite",
        "Movimenti",
      ],
    }),
    updateUscita: builder.mutation({
      query: (uscita) => ({
        url: `/impianti/uscite/${uscita.id}`,
        method: "PUT",
        body: {
          id: uscita.id,
          id_impianto: uscita.idImpianto,
          id_ricetta: Number(uscita.ricetta.id),
          id_cliente: uscita.cliente?.id ? Number(uscita.cliente?.id) : null,
          id_cantiere: uscita.cantiere?.id ? Number(uscita.cantiere?.id) : null,
          numero: uscita.numero,
          destinazione: uscita.destinazione,
          data: uscita.data,
          qta: uscita.qta,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Impianti", id: arg.id_impianto },
        { type: "Uscite", id: arg.id },
        "Movimenti",
      ],
    }),
  }),
});

export const {
  useGetUsciteQuery,
  useDeleteUscitaMutation,
  useAddNewUscitaMutation,
  useGetProssimaUscitaQuery,
  useUpdateUscitaMutation,
} = extendedApiSlice;

// Define function to get selectors based on arguments (query) of getSetups
export const getUsciteSelectors = (query) => {
  const selectSetupsResult = extendedApiSlice.endpoints.getUscite.select(query);

  const adapterSelectors = createSelector(selectSetupsResult, (result) => {
    return usciteAdapter.getSelectors(() => result?.data ?? initialState);
  });

  return {
    selectAllUscite: createSelector(adapterSelectors, (s) =>
      s.selectAll(undefined)
    ),
    selectEntities: createSelector(adapterSelectors, (s) =>
      s.selectEntities(undefined)
    ),
    selectIds: createSelector(adapterSelectors, (s) => s.selectIds(undefined)),
    selectTotal: createSelector(adapterSelectors, (s) =>
      s.selectTotal(undefined)
    ),
    selectUscitaById: (id) =>
      createSelector(adapterSelectors, (s) => s.selectById(s, id)),
  };
};
