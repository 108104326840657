import Uscita from "./Uscita";
import Entrata from "./Entrata";
import { useEffect, useMemo, useState } from "react";
import { getEntrateSelectors, useGetEntrateQuery } from "../entrateApiSlice";
import { useGetUsciteQuery } from "../usciteApiSlice";
import { useParams, useNavigate } from "react-router-dom";
import { getUsciteSelectors } from "../usciteApiSlice";
import { useSelector } from "react-redux";
import WhiteButton from "../../../components/buttons/WhiteButton";
import UltimiMovimentiSkeleton from "./skeletons/UltimiMovimentiSkeleton";

const UltimiMovimenti = ({ maxItems = 5 }) => {
  const { impiantoId } = useParams();
  const navigate = useNavigate();

  const { isLoading: loadingEntrate } = useGetEntrateQuery(Number(impiantoId));
  const { isLoading: loadingUscite } = useGetUsciteQuery(Number(impiantoId));

  const isLoading = loadingEntrate || loadingUscite;

  const { selectAllEntrate } = useMemo(() => {
    return getEntrateSelectors(Number(impiantoId));
  }, [impiantoId]);
  const { selectAllUscite } = useMemo(
    () => getUsciteSelectors(Number(impiantoId)),
    [impiantoId]
  );

  const entrate = useSelector(selectAllEntrate);
  const uscite = useSelector(selectAllUscite);

  const ultimiMovimenti = [
    ...entrate.map((entrata) => ({
      tipo: "entrata",
      ...entrata,
    })),
    ...uscite.map((uscita) => ({
      tipo: "uscita",
      ...uscita,
    })),
  ]
    .reverse()
    .sort(function (a, b) {
      return new Date(b.data) - new Date(a.data);
    })
    .slice(0, maxItems);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex flex-col md:flex-row md:items-center">
          <div>
            <h2
              id="applicant-information-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Ultimi movimenti
            </h2>
            <div className="mt-1 max-w-2xl text-sm text-gray-500 order-2">
              Ultimi {maxItems} movimenti del'impianto selezionato.
            </div>
          </div>

          <div className="md:flex md:flex-row md:space-x-3 md:ml-auto order-last space-y-2 mt-4 md:mt-0">
            <WhiteButton
              onClick={() => navigate("entrate")}
              className="w-full mt-auto"
            >
              Entrate
            </WhiteButton>
            <WhiteButton
              onClick={() => navigate("uscite")}
              className="w-full mt-auto"
            >
              Uscite
            </WhiteButton>
          </div>
        </div>
      </div>
      {isLoading ? (
        <UltimiMovimentiSkeleton />
      ) : (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <ul
            role="list"
            className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
          >
            {ultimiMovimenti.map((movimento, index) =>
              movimento.tipo === "uscita" ? (
                <Uscita uscita={movimento} key={index} />
              ) : (
                <Entrata entrata={movimento} key={index} />
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UltimiMovimenti;
