import LoadingScreen from "../../../components/LoadingScreen";
import { Outlet } from "react-router-dom";
import { useGetClientiQuery } from "../clientiApiSlice";

const ClientiLayout = () => {
  const { isLoading, isSuccess, isError, error } = useGetClientiQuery();

  let content;

  if (isLoading) content = <LoadingScreen />;

  if (isSuccess) content = <Outlet />;

  if (isError) content = <div>Errore: {error}</div>;

  return content;
};

export default ClientiLayout;
