import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../components/forms/Input";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AggiungiRisorseModal from "../../AggiungiRisorseModal";
import { useUpdateRicettaMutation } from "../../impiantiApiSlice";
import TextArea from "../../../../components/forms/TextArea";
import EuroInput from "../../../../components/forms/EuroInput";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import LoadingScreen from "../../../../components/LoadingScreen";

const EditaRicettaForm = ({ existingRicetta, risorse }) => {
  const { impiantoId, ricettaId } = useParams();

  const [updateRicetta] = useUpdateRicettaMutation();

  const navigate = useNavigate();

  const emptyRicetta = {
    id: ricettaId,
    nome: existingRicetta.nome,
    descrizione: existingRicetta.descrizione,
    um: existingRicetta.um,
    costo: existingRicetta.costo,
    costoProduzione: existingRicetta.costoProduzione,
    prezzo: existingRicetta.prezzo,
    idImpianto: parseInt(impiantoId),
  };

  const existingIngredientsIds = [
    ...existingRicetta.ingredienti.map((i) => i.risorsa.id),
  ];

  const existingIngredients = [
    ...risorse
      .filter((risorsa) => existingIngredientsIds.includes(Number(risorsa.id)))
      .map((risorsa) => ({
        risorsa,
        qta: existingRicetta.ingredienti.find(
          (ingrediente) => Number(ingrediente.risorsa.id) === Number(risorsa.id)
        ).qta,
        id: risorsa.id,
      })),
  ];

  const [ricetta, setRicetta] = useState(emptyRicetta);
  const [open, setOpen] = useState(false);
  const [ingredients, setIngredients] = useState(existingIngredients);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    setRicetta({
      ...ricetta,
      [e.target.name]: e.target.value,
    });
  };

  const canSave =
    [
      ricetta.nome,
      ricetta.um,
      ricetta.costo,
      ricetta.prezzo,
      ricetta.costoProduzione,
    ].every(Boolean) &&
    ingredients.length > 0 &&
    ingredients.every((ingredient) => ingredient.qta !== "");

  const onSaveRicettaClicked = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    if (canSave) {
      try {
        setLoading(true);
        await updateRicetta({
          ...ricetta,
          ingredienti: [...ingredients],
        }).unwrap();

        setRicetta(emptyRicetta);
        navigate(`/${impiantoId}/ricette/${ricettaId}`);
      } catch (err) {
        setLoading(false);
        console.error("Failed to save new impianto", err);
      }
    }
  };

  const onIngredientChange = (e) => {
    setIngredients((prev) =>
      prev.map((obj) => {
        if (obj.id.toString() === e.target.name) {
          let newValue = e.target.value
            .replace(/,/g, ".")
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");

          return {
            ...obj,
            qta: newValue,
          };
        }

        return obj;
      })
    );
  };

  const handleIngredientRemove = (id) => {
    setIngredients((prev) =>
      prev.filter((obj) => {
        return obj.id !== id;
      })
    );
  };

  const handleAddRisorse = (newRisorse) => {
    setIngredients((prev) => [
      ...prev.filter((risorsa) => {
        if (newRisorse.includes(risorsa?.risorsa)) return risorsa;
      }),
      ...newRisorse
        .filter((risorsa) => !ingredients.some((obj) => obj.id === risorsa.id))
        .map((risorsa) => ({
          risorsa: risorsa,
          qta: "",
          id: risorsa.id,
        })),
    ]);
  };

  let content;

  content = (
    <section>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveRicettaClicked}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Nome*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="nome"
                  value={ricetta.nome}
                  setValue={onInputChange}
                  errorMessage="Inserire un nome per la ricetta"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Nome della ricetta. Sarà il nome mostrato sui documenti di
                  trasporto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Descrizione
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <TextArea
                  className="max-w-lg"
                  name="descrizione"
                  value={ricetta.descrizione}
                  setValue={onInputChange}
                />

                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Descrizione o eventuali note relative al prodotto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Unità di misura*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-xs"
                  name="um"
                  value={ricetta.um}
                  setValue={onInputChange}
                  errorMessage="Inserire una unità di misura per la ricetta"
                  required={submitted}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Costo di produzione*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <EuroInput
                  name="costoProduzione"
                  value={ricetta.costoProduzione}
                  setValue={onInputChange}
                  errorMessage="Il costo di produzione della la ricetta deve essere un numero"
                  required={submitted}
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                />
                <p className="mt-2 text-sm text-gray-500">
                  Costo della sola produzione (
                  <span className="underline font-semibold">
                    esclusi i materiali
                  </span>
                  ) di una unità di prodotto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Costo*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <EuroInput
                  name="costo"
                  value={ricetta.costo}
                  setValue={onInputChange}
                  errorMessage="Il costo totale della la ricetta deve essere un numero"
                  required={submitted}
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                />
                <p className="mt-2 text-sm text-gray-500">
                  Costo totale di una unità di prodotto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Prezzo*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <EuroInput
                  name="prezzo"
                  value={ricetta.prezzo}
                  setValue={onInputChange}
                  errorMessage="Il prezzo di vendita della ricetta deve essere un numero"
                  required={submitted}
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                />
                <p className="mt-2 text-sm text-gray-500">
                  Prezzo di vendita di una unità di prodotto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="postal-code"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Ingredienti*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-3xl">
                {ingredients.length > 0 && (
                  <div className="bg-white shadow-sm overflow-hidden sm:rounded-md sm:border sm:border-gray-300">
                    <ul role="list" className="divide-y divide-gray-200">
                      {ingredients.map((ingredient) => (
                        <li key={ingredient?.id}>
                          <div className="sm:grid sm:gap-20 sm:grid-cols-2 items-center justify-between px-2 py-4 sm:px-4">
                            <div className="flex items-center gap-4">
                              <button
                                onClick={() =>
                                  handleIngredientRemove(ingredient?.id)
                                }
                                type="button"
                                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-red-600 border-red-600 border-2  hover:border-red-700 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              >
                                <XMarkIcon
                                  className="h-4 w-4"
                                  aria-hidden="true"
                                />
                              </button>
                              <p>{ingredient?.risorsa?.nome}</p>
                            </div>

                            <div className="flex items-center justify-between">
                              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                                {ingredient?.risorsa?.um}
                              </span>
                              <div>
                                <Input
                                  placeholder="Quantità"
                                  className="max-w-xs ml-auto shadow-none"
                                  name={ingredient?.id}
                                  value={ingredient?.qta}
                                  setValue={onIngredientChange}
                                  errorMessage="Inserire la quantità"
                                  required={submitted}
                                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="flex justify-end mt-2">
                  <PrimaryButton type="button" onClick={() => setOpen(true)}>
                    Aggiungi Risorse
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end space-x-3">
            <WhiteButton
              onClick={() => navigate(`/${impiantoId}/ricette/${ricettaId}`)}
            >
              Annulla
            </WhiteButton>
            <PrimaryButton type="submit">Salva</PrimaryButton>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;

  return (
    <>
      <AggiungiRisorseModal
        open={open}
        setOpen={setOpen}
        setNewRisorse={handleAddRisorse}
        selectedRisorse={ingredients}
      />
      {content}
    </>
  );
};

export default EditaRicettaForm;
