import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCantiereById,
  useGetUsciteCantiereQuery,
} from "../cantieriApiSlice";
import { PencilIcon } from "@heroicons/react/24/outline";
import Uscita from "../../impianti/components/Uscita";
import useNavigation from "../../../hooks/useNavigation";
import LoadingSpinner from "../../../components/LoadingScreen/LoadingSpinner";
import { selectCurrentUser } from "../../auth/authSlice";

const CantierePage = () => {
  const { cantiereId } = useParams();
  const cantiere = useSelector((state) =>
    selectCantiereById(state, Number(cantiereId))
  );
  const user = useSelector(selectCurrentUser);

  useNavigation([{ name: cantiereId, newName: cantiere.nome }]);

  const { data, isLoading } = useGetUsciteCantiereQuery(Number(cantiereId));

  return (
    <div className="flex-1 bg-white rounded-md sm:py-6 sm:px-10 sm:max-w-6xl mx-auto">
      <div className="py-8 xl:py-10">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 xl:grid xl:max-w-full">
          <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Cantiere{" "}
                <span className="font-medium text-gray-900">
                  {cantiere?.nome}
                </span>
              </h1>

              <p className="mt-2 text-sm text-gray-500">
                Descrizione: {cantiere?.descrizione}
              </p>

              <p className="mt-2 text-sm text-gray-500">
                Indirizzo:{" "}
                <span className="font-medium text-gray-900">
                  {cantiere?.indirizzo}
                </span>
              </p>
            </div>
            {user?.role === 104 ? (
              <div className="mt-4 flex space-x-3 md:mt-0">
                <Link
                  to="edita"
                  className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                >
                  <PencilIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Edita</span>
                </Link>
              </div>
            ) : undefined}
          </div>
          <div>
            <ul
              role="list"
              className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
            >
              {!isLoading ? (
                data.data?.map((movimento, index) => (
                  <Uscita
                    uscita={movimento}
                    key={index}
                    path={`/${movimento.idImpianto}/uscite/`}
                  />
                ))
              ) : (
                <div className="flex flex-col justify-center items-center my-6">
                  <LoadingSpinner />
                  Caricamento...
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CantierePage;
