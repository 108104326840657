import useRandomWidth from "../../../../hooks/useRandomWitsh";

const RicetteRisorseSkeleton = () => {
  const [width] = useRandomWidth();

  return (
    <>
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Ricette
        </h2>

        {/* RICETTE */}
        <div className="mt-6 flow-root">
          <ul
            role="list"
            className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
          >
            {Array.from(Array(3).keys()).map((item) => (
              <li key={item}>
                <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                  <div className="min-w-0 flex-1 px-4">
                    <div className={`h-2 bg-slate-200 rounded w-${width()}`} />
                    <div className="flex justify-between items-center mt-2">
                      <div
                        className={`h-2 bg-slate-200 rounded w-${width()}`}
                      />

                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {/* ICON */}
                        <div className="rounded-full bg-slate-200 h-5 w-5 mr-1.5" />
                        {/* ICON */}
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <div
                        className={`h-2 bg-slate-200 rounded w-${width()}`}
                      />
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {/* ICON */}
                        <div className="rounded-full bg-slate-200 h-5 w-5 mr-1.5" />
                        {/* ICON */}
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Risorse
        </h2>

        <div className="mt-2 flow-root">
          <ul
            role="list"
            className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
          >
            {Array.from(Array(5).keys()).map((item) => (
              <li key={item}>
                <div className="group block py-5 px-4 sm:py-6 sm:px-0">
                  <div className="min-w-0 flex-1 px-4">
                    <div className={`h-2 bg-slate-200 rounded w-${width()}`} />
                    <div className="flex justify-between items-center mt-2">
                      <div
                        className={`h-2 bg-slate-200 rounded w-${width()}`}
                      />

                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <div
                        className={`h-2 bg-slate-200 rounded w-${width()}`}
                      />
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        {/* ICON */}
                        <div className="rounded-full bg-slate-200 h-5 w-5 mr-1.5" />
                        {/* ICON */}
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default RicetteRisorseSkeleton;
