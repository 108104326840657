import {
  ArchiveBoxArrowDownIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  TruckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { convertDate } from "../../../utilities/date";
import { Link, useParams } from "react-router-dom";

const Entrata = ({ entrata, path = "entrate/" }) => {
  return (
    <li>
      <Link to={`${path}${entrata?.id}`} className="group block">
        <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">
              <ArchiveBoxArrowDownIcon className="h-8 w-8 group-hover:opacity-75 text-green-500" />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="truncate text-sm font-medium text-casalucci">
                  DDT n° <span>{entrata?.numero}</span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  {entrata.fornitore ? (
                    <>
                      <TruckIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="truncate capitalize">
                        {entrata?.fornitore?.nome}
                      </span>
                    </>
                  ) : (
                    <>
                      <UserIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="truncate capitalize">
                        {entrata?.cliente?.nome}
                      </span>
                    </>
                  )}
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <p className="text-sm text-gray-700">
                    Consegnato:{" "}
                    <span className="text-gray-900 font-semibold">
                      {convertDate(entrata?.data)}
                    </span>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {entrata?.protocollo ? (
                      <>
                        <CheckCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                          aria-hidden="true"
                        />
                        Fattura protocollo n° {entrata?.protocollo}
                      </>
                    ) : (
                      <>
                        <ExclamationCircleIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-amber-400"
                          aria-hidden="true"
                        />
                        Non ancora fatturata
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default Entrata;
