import Select from "../../../components/forms/Select";
import { useSelector } from "react-redux";
import {
  selectAllClienti,
  useGetClientiQuery,
} from "../../clienti/clientiApiSlice";

const SelectCliente = ({ selectedCliente, handleChange, submitted }) => {
  const {} = useGetClientiQuery();

  const clienti = useSelector(selectAllClienti);

  const valid = () => {
    if (submitted)
      return selectedCliente && Object.keys(selectedCliente).length !== 0;

    return true;
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Cliente*
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-1 sm:max-w-xs">
        <Select
          name="cliente"
          items={clienti}
          selectedItem={selectedCliente}
          setSelectedItem={handleChange}
          errorMessage="Inserire un cliente per il movimento"
          isValid={valid()}
        />
      </div>
    </div>
  );
};

export default SelectCliente;
