export function generateRequestError(id, error, type = "error", show = true) {
  return {
    id,
    type,
    title: error?.data?.message,
    body: null,
    status: error?.status,
    show,
  };
}

export function generateError(id, title, body, type = "error", show = true) {
  return {
    id,
    type,
    title,
    body,
    show,
  };
}
