import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";

import Input from "../../components/forms/Input";
import {
  useDeleteRisorsaMutation,
  useUpdateRisorsaMutation,
} from "./risorseApiSlice";
import TextArea from "../../components/forms/TextArea";
import ErrorAlert from "../../components/alerts/ErrorAlert";
import EliminaModal from "./EliminaModal";
import Select from "../../components/forms/Select";
import { CATEGORIE_RISORSA } from "./constants";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import WhiteButton from "../../components/buttons/WhiteButton";
import SuccessPopupAlert from "../../components/alerts/SuccessPopupAlert";

const RisorsaSlide = ({
  risorsa = {
    nome: "",
    categoria: {},
    note: "",
    um: "",
    costo: "",
    rifiuto: false,
  },
  open,
  setOpen,
}) => {
  const [risorsaForm, setRisorsaForm] = useState({
    foto: null,
  });
  const [error, setError] = useState({
    show: false,
    body: "",
  });

  const [success, setSuccess] = useState({
    show: false,
    title: "",
    body: "",
  });

  const closeSuccessAlertCallback = () => {
    setSuccess({
      show: false,
      title: "",
      body: "",
    });
  };

  const [openDelete, setOpenDelete] = useState(false);

  const categorie = CATEGORIE_RISORSA.sort().map((categoria, index) => ({
    id: index,
    name: categoria,
  }));

  useEffect(() => {
    setRisorsaForm({
      ...risorsa,
      categoria: categorie.find(
        (categoria) => categoria.name === risorsa?.categoria
      ),
    });
    setError({ show: false, body: "" });
  }, [risorsa]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setRisorsaForm({
          ...risorsa,
          categoria: categorie.find(
            (categoria) => categoria.name === risorsa?.categoria
          ),
        });
        setError({ show: false, body: "" });
      }, 700);
    }
  }, [open]);

  const [updateRisorsa] = useUpdateRisorsaMutation();
  const [deleteRisorsa] = useDeleteRisorsaMutation();

  const handleInputChange = (e) => {
    let newValue = e.target.value;

    if (e.target.name === "costo") {
      newValue = newValue
        .replace(/,/g, ".")
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");

      if (newValue.charAt(0) === ".") newValue = "0".concat(newValue);
    }

    setRisorsaForm({
      ...risorsaForm,
      [e.target.name]: newValue,
    });
  };

  const canSave = [risorsaForm.nome, risorsaForm.costo, risorsaForm.um].every(
    Boolean
  );

  const deleteAction = async () => {
    try {
      await deleteRisorsa(risorsa).unwrap();

      setSuccess({
        show: true,
        title: "Risorsa eliminata con successo",
        body: "La risorsa è stata eliminata con successo dal server e non potrà più essere usata per registrazioni future.",
      });

      setOpen(false);
    } catch (err) {
      setError({
        show: true,
        body: err?.data?.message,
      });
    } finally {
      setOpenDelete(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (canSave) {
      try {
        await updateRisorsa({
          ...risorsaForm,
          categoria: risorsaForm?.categoria?.name,
        }).unwrap();

        setSuccess({
          show: true,
          title: "Risorsa salvata con successo",
          body: "La risorsa è stata salvata con successo sul server e tutte le registrazione passate saranno aggiornate di conseguenza.",
        });

        setOpen(false);
      } catch (err) {
        setError({
          show: true,
          body: err?.data?.message,
        });
      }
    } else {
      setError({
        show: true,
        body: "Compila tutti i campi obbligatori per salvare.",
      });
    }
  };

  const onCategoriaChange = (newCategoria) => {
    setRisorsaForm({
      ...risorsaForm,
      categoria: newCategoria,
    });
  };

  const deleteModal = (
    <EliminaModal
      risorsa={risorsa}
      deleteAction={deleteAction}
      openDelete={openDelete}
      setOpenDelete={setOpenDelete}
    />
  );

  //console.log(risorsa, risorsaForm);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-700"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10<">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {risorsa.nome}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {/* Replace with your content */}

                        {error.show ? <ErrorAlert title={error.body} /> : null}

                        <div className="pb-16 space-y-6">
                          <form onSubmit={handleSave}>
                            <div>
                              <h3 className="font-medium text-gray-900">
                                Informazioni
                              </h3>

                              <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                                <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">Nome*</dt>
                                  <Input
                                    value={risorsaForm.nome}
                                    setValue={handleInputChange}
                                    name="nome"
                                    className="w-60 ml-auto"
                                  />
                                </div>

                                <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">Categoria*</dt>
                                  <div className="block max-w-min">
                                    <Select
                                      name="categoria"
                                      selectedItem={risorsaForm.categoria}
                                      setSelectedItem={onCategoriaChange}
                                      items={categorie}
                                      className="w-60 ml-auto"
                                    />
                                  </div>
                                </div>

                                <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">Note</dt>
                                  <TextArea
                                    value={risorsaForm.note || ""}
                                    setValue={handleInputChange}
                                    name="note"
                                    className="w-60 ml-auto"
                                  />
                                </div>

                                {/* FOTO
                                  <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">Foto</dt>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <div className="flex items-center">
                                      {risorsaForm?.foto ? (
                                        <img
                                          className="h-12 w-12 flex-shrink-0 rounded-full bg-gray-100"
                                          src={URL.createObjectURL(
                                            risorsaForm?.foto
                                          )}
                                        />
                                      ) : risorsa?.foto ? (
                                        <img
                                          className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                                          src={createImageLink(risorsa?.foto)}
                                          alt=""
                                        />
                                      ) : (
                                        <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                                          <svg
                                            className="h-full w-full text-gray-300"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                          </svg>
                                        </span>
                                      )}

                                      <label
                                        htmlFor="foto"
                                        className="cursor-pointer ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                      >
                                        <input
                                          id="foto"
                                          name="foto"
                                          type="file"
                                          className="sr-only"
                                          onChange={(e) => {
                                            setRisorsaForm({
                                              ...risorsa,
                                              foto: e.target.files[0],
                                            });
                                          }}
                                        />
                                        cambia
                                      </label>
                                    </div>
                                  </div>
                                </div> */}

                                <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">UM*</dt>
                                  <Input
                                    value={risorsaForm.um}
                                    setValue={handleInputChange}
                                    name="um"
                                    className="w-60 ml-auto"
                                  />
                                </div>

                                <div className="py-3 flex justify-between text-sm font-medium">
                                  <dt className="text-gray-500">Costo*</dt>
                                  <Input
                                    value={risorsaForm.costo}
                                    setValue={handleInputChange}
                                    name="costo"
                                    className="w-60 ml-auto"
                                  />
                                </div>

                                <div className="py-3 grid grid-cols-3 justify-between text-sm font-medium">
                                  <dt className="text-gray-500">Opzioni</dt>
                                  <div className="space-y-4">
                                    <div className="relative flex items-start ml-7">
                                      <div className="flex h-5 items-center">
                                        <input
                                          id="rifiuto"
                                          name="rifiuto"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-casalucci focus:ring-casalucci-400"
                                          onChange={() =>
                                            setRisorsaForm({
                                              ...risorsaForm,
                                              rifiuto: !risorsaForm.rifiuto,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="rifiuto"
                                          className="font-medium text-gray-700"
                                        >
                                          Rifiuto
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {Boolean(risorsaForm?.rifiuto) && (
                                  <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">CER*</dt>
                                    <Input
                                      value={risorsaForm?.cer}
                                      setValue={handleInputChange}
                                      name="cer"
                                      className="ml-auto w-60"
                                    />
                                  </div>
                                )}
                              </dl>
                            </div>
                            <div className="flex mt-3">
                              <PrimaryButton type="submit" className="flex-1">
                                Salva
                              </PrimaryButton>
                              <WhiteButton
                                type="button"
                                onClick={() => setOpenDelete(true)}
                                className="flex-1 ml-3"
                              >
                                Elimina
                              </WhiteButton>
                            </div>
                          </form>
                        </div>

                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {deleteModal}
      {success?.show && (
        <SuccessPopupAlert
          {...success}
          closeCallback={closeSuccessAlertCallback}
        />
      )}
    </>
  );
};

export default RisorsaSlide;
