import Entrata from "../components/Entrata";
import { useMemo } from "react";
import { getEntrateSelectors } from "../entrateApiSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EntrateList from "../components/EntrateList";
import WhiteButton from "../../../components/buttons/WhiteButton";
import useNavigation from "../../../hooks/useNavigation";
import { selectImpiantoById } from "../impiantiApiSlice";

const EntratePage = () => {
  const navigate = useNavigate();
  const { impiantoId } = useParams();
  const location = useLocation();

  const { selectAllEntrate } = useMemo(() => {
    return getEntrateSelectors(Number(impiantoId));
  }, [impiantoId]);

  const entrate = useSelector(selectAllEntrate);
  const { nome, rifiuti } = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([{ name: impiantoId, newName: nome }]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 md:flex justify-between items-center">
        <div>
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Entrate
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Lista di tutti i movimenti di risorse in entrata nell'impianto
          </p>
        </div>
        <div className="md:space-x-4">
          {Boolean(rifiuti) && (
            <WhiteButton
              onClick={() => navigate("scarico")}
              className="mt-4 w-full md:mt-0 md:w-auto"
            >
              Nuovo Scarico
            </WhiteButton>
          )}

          <WhiteButton
            onClick={() =>
              navigate("nuova", {
                state: { from: location },
                replace: true,
              })
            }
            className="mt-2 w-full md:mt-0 md:w-auto"
          >
            Nuova Entrata
          </WhiteButton>
        </div>
      </div>
      <EntrateList
        entrate={entrate?.sort((a, b) => new Date(b.data) - new Date(a.data))}
      />
    </div>
  );
};

export default EntratePage;
