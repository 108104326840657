import { Link } from "react-router-dom";
import {
  classNames,
  filterArray,
  formatoNumeriGrandi,
} from "../../../utilities/generali";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";
import usePagination from "../../../hooks/usePagination";
import SearchBox from "./SearchBox";
import { useMemo, useState } from "react";

const CantieriTable = ({ cantieri }) => {
  const [searchTerm, setSearchTerm] = useState("");

  //Mostra solo i cantieri che corrispondono al termine di ricerca e che sono aperti
  const cantieriToDisplay = useMemo(() => {
    return filterArray(cantieri, searchTerm, ["nome", "indirizzo"]).filter(
      (cantiere) => Number(cantiere.stato) === 1
    );
  }, [searchTerm, cantieri]);

  const [pagination, currentPage, maxCantieri, selectMaxCantieri] =
    usePagination(cantieriToDisplay);

  return (
    <div className="h-full">
      <div className="sm:flex justify-between items-center mt-6 mb-2">
        <div>{selectMaxCantieri}</div>
        <div>
          <SearchBox
            searchTerm={searchTerm}
            handleChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          />
        </div>
      </div>
      <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Nome
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Importo
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Indirizzo
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Tipo
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {cantieriToDisplay?.map(
              (cantiere, index) =>
                index >= (currentPage - 1) * maxCantieri &&
                index < currentPage * maxCantieri && (
                  <tr
                    key={cantiere.id}
                    className={classNames(
                      index % 2 === 0 ? undefined : "bg-gray-50",
                      "hover:bg-casalucci-400 hover:bg-opacity-20"
                    )}
                  >
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                      {cantiere.nome}
                      <dl className="font-normal lg:hidden">
                        <dd className="mt-1 truncate text-gray-700 flex space-x-3 items-center">
                          {Boolean(cantiere.importo) ? (
                            <>
                              <CurrencyEuroIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              {`${formatoNumeriGrandi(cantiere.importo)}`}
                            </>
                          ) : (
                            "-"
                          )}
                        </dd>

                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          {cantiere.indirizzo}
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:flex lg:space-x-3 lg:items-center">
                      {Boolean(cantiere.importo) ? (
                        <>
                          <CurrencyEuroIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {`${formatoNumeriGrandi(cantiere.importo)}`}
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {cantiere.indirizzo}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      {Number(cantiere.pubblico) ? (
                        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-600 text-casalucci-100">
                          Pubblico
                        </p>
                      ) : (
                        <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-100 text-casalucci-600">
                          Privato
                        </p>
                      )}
                    </td>
                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Link
                        to={`${cantiere.id}`}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Vedi
                      </Link>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      {pagination}
    </div>
  );
};

export default CantieriTable;
