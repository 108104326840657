import { useState } from "react";
import { useUpdateClienteMutation } from "../clientiApiSlice";
import { Link, useNavigate } from "react-router-dom";
import ErrorAlert from "../../../components/alerts/ErrorAlert";
import Input from "../../../components/forms/Input";
import LoadingScreen from "../../../components/LoadingScreen";

const EditaClienteForm = ({ existingCliente }) => {
  const [cliente, setCliente] = useState(existingCliente);
  const [error, setError] = useState({
    show: false,
    body: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const canSave = [cliente.nome].every(Boolean);

  const [updateCliente] = useUpdateClienteMutation();

  const navigate = useNavigate();

  const onInputChange = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  const onSaveClienteClicked = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (canSave) {
      setError({
        show: false,
        body: "",
      });

      try {
        setLoading(true);
        await updateCliente({ data: cliente, id: existingCliente.id }).unwrap();

        navigate("/clienti");
      } catch (err) {
        setError({
          show: true,
          body: err?.data?.message,
        });
      }
    } else {
      setLoading(false);
      setError({
        show: true,
        body: "Compila i campi obbligatori per poter salvare",
      });
    }
  };

  if (loading) return <LoadingScreen />;
  return (
    <section className="">
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveClienteClicked}
      >
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 sm:p-6 bg-white space-y-8 divide-y divide-gray-200 sm:space-y-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edita Cliente
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                I campi indicati con * sono obbligatori.
              </p>
              {error.show ? <ErrorAlert title={error.body} /> : null}
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="nome"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Nome*
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="nome"
                    value={cliente.nome}
                    setValue={onInputChange}
                    required={submitted}
                    errorMessage="Inserisci un nome per la risorsa"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="nome"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Indirizzo
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="indirizzo"
                    value={cliente.indirizzo}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="telefono"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Telefono
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="telefono"
                    type="number"
                    value={cliente.telefono}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="mail"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Mail
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="mail"
                    type="email"
                    value={cliente.mail}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="pec"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  PEC
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-lg"
                    name="pec"
                    type="email"
                    value={cliente.pec}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cf"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Codice Fiscale
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-sm"
                    name="cf"
                    value={cliente.cf}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cu"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Codice Univoco Fatturazione
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-sm"
                    name="cu"
                    value={cliente.cu}
                    setValue={onInputChange}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="pi"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Partita IVA
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Input
                    className="max-w-sm"
                    name="pi"
                    value={cliente.pi}
                    setValue={onInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">
            <Link
              to={`/clienti/${cliente.id}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditaClienteForm;
