import { setPathChanges } from "../features/auth/navigationSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const useNavigation = (items = []) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPathChanges(items));
  }, [dispatch, items, location.pathname]);
};

export default useNavigation;
