import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import {
  selectImpiantoById,
  useDeleteRicettaMutation,
} from "../impiantiApiSlice";
import { useSelector } from "react-redux";
import {
  CakeIcon,
  CalendarIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { formatDateObjectToItaliana } from "../../../utilities/date";
import { TruckIcon } from "@heroicons/react/24/solid";
import EliminaModal from "../components/EliminaRicettaModal";
import ErrorAlert from "../../../components/alerts/ErrorAlert";
import WhiteButton from "../../../components/buttons/WhiteButton";
import useNavigation from "../../../hooks/useNavigation";

const RicettaPage = () => {
  const { ricettaId, impiantoId } = useParams();
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteRicetta] = useDeleteRicettaMutation();
  const [error, setError] = useState({
    message: "",
    show: false,
  });

  const selector = useMemo(
    () => (state) => selectImpiantoById(state, Number(impiantoId)),
    [impiantoId]
  );
  const { ricette } = useSelector(selector);

  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  const ricetta = ricette.find(
    (ricetta) => Number(ricetta.id) === Number(ricettaId)
  );

  useNavigation([
    { name: impiantoId, newName: impianto.nome },
    { name: ricettaId, newName: ricetta.nome },
  ]);

  const deleteAction = async () => {
    try {
      await deleteRicetta(Number(ricettaId)).unwrap();

      navigate(`/${impiantoId}`);
    } catch (error) {
      if (error.status === 422) {
        setError({
          message: error.data.message,
          show: true,
        });
        setOpenDelete(false);
        return true;
      }
    }
  };

  const modalProps = {
    ricetta: ricetta,
    openDelete,
    setOpenDelete,
    deleteAction,
  };

  const errorAlertProps = {
    title: error.message,
  };

  let content = (
    <div className="flex-1 bg-white rounded-md py-6 px-10 max-w-6xl mx-auto">
      {error.show && <ErrorAlert {...errorAlertProps} />}

      <div className="py-8 xl:py-10">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8 xl:grid xl:max-w-full xl:grid-cols-3">
          <div className="xl:col-span-2 xl:border-r xl:border-gray-200 xl:pr-8">
            <div>
              <div>
                <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-8">
                  <div className="relative">
                    <h1 className="text-2xl font-bold text-gray-900">
                      {ricetta?.nome}
                    </h1>
                    <p className="hidden md:block mt-2 text-sm text-gray-500 truncate absolute max-w-xl">
                      {ricetta?.descrizione}
                    </p>
                  </div>
                  <div className="mt-4 flex space-x-3 md:mt-0">
                    <WhiteButton callback={() => navigate("edita")}>
                      <PencilIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Edita</span>
                    </WhiteButton>
                    <WhiteButton callback={() => setOpenDelete(true)}>
                      <TrashIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Elimina</span>
                    </WhiteButton>
                  </div>
                </div>
                <aside className="mt-8 xl:hidden">
                  <h2 className="sr-only">Dettagli</h2>
                  <div className="space-y-5">
                    <div className="flex items-center space-x-2">
                      <CakeIcon
                        className="h-5 w-5 text-casalucci"
                        aria-hidden="true"
                      />
                      <span className="text-sm font-medium text-casalucci-600">
                        Ricetta
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <TruckIcon
                        className="h-5 w-5 text-casalucci"
                        aria-hidden="true"
                      />
                      <span className="text-sm font-medium text-casalucci-600">
                        Movimento
                      </span>
                    </div>

                    <div className="flex items-center space-x-2">
                      <CalendarIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-700 font-medium">
                        Creato in data{" "}
                        <time dateTime={ricetta?.created_at}>
                          {formatDateObjectToItaliana(ricetta?.created_at)}
                        </time>
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 space-y-8 border-t border-b border-gray-200 py-6">
                    <div>
                      <h2 className="text-sm font-medium text-gray-500">
                        Creato Da
                      </h2>
                      <ul role="list" className="mt-3 space-y-3">
                        <li className="flex justify-start">
                          <a href="#" className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-5 w-5 rounded-full"
                                src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              Luca Casalucci
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div></div>
                  </div>
                </aside>
                <div className="py-3 xl:pb-0">
                  <div className="divide-gray-200 divide-y">
                    <div className="py-6">
                      <p>Ingredienti:</p>
                      <ol className="mt-2 text-sm text-gray-700 font-light">
                        {ricetta.ingredienti.map((ingrediente) => (
                          <li key={ingrediente.id} className="flex">
                            {ingrediente.risorsa.nome}
                            <span className="ml-auto">
                              {ingrediente.qta}{" "}
                              {ingrediente.risorsa.um !== "cad" &&
                                ingrediente.risorsa.um}
                            </span>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="md:grid md:items-start md:space-y-1 xl:border-t xl:pt-6 md:grid-cols-1 border-t">
                  <div className=" flex">
                    Costo:{" "}
                    <span className="ml-auto">
                      {ricetta?.costo ? `€ ${ricetta.costo}` : "-"}
                    </span>
                  </div>
                  <div className=" flex">
                    Costo Produzione:{" "}
                    <span className="ml-auto">
                      {ricetta?.costoProduzione
                        ? `€ ${ricetta.costoProduzione}`
                        : "-"}
                    </span>
                  </div>
                  <div className=" flex">
                    Prezzo di vendita:{" "}
                    <span className="ml-auto">
                      {ricetta?.prezzo ? `€ ${ricetta.prezzo}` : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside className="hidden xl:block xl:pl-8">
            <h2 className="sr-only">Dettagli</h2>
            <div className="space-y-5">
              <div className="flex items-center space-x-2">
                <CakeIcon
                  className="h-5 w-5 text-casalucci"
                  aria-hidden="true"
                />
                <span className="text-sm font-medium text-casalucci-600">
                  Ricetta
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="text-sm text-gray-700 font-medium">
                  Creato in data{" "}
                  <time dateTime={ricetta?.created_at}>
                    {formatDateObjectToItaliana(ricetta?.created_at)}
                  </time>
                </span>
              </div>
            </div>
            <div className="mt-6 space-y-8 border-t border-gray-200 py-6">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Creato da</h2>
                <ul role="list" className="mt-3 space-y-3">
                  <li className="flex justify-start">
                    <a href="#" className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-5 w-5 rounded-full"
                          src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="text-sm font-medium text-gray-900">
                        Luca Casalucci
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <EliminaModal {...modalProps} />
      {content}
    </>
  );
};

export default RicettaPage;
