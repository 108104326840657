import { Outlet } from "react-router-dom";
import { useGetImpiantiQuery } from "./impiantiApiSlice";
import LoadingScreen from "../../components/LoadingScreen";

const ImpiantiLayout = () => {
  const { isLoading, isSuccess, isError, error } = useGetImpiantiQuery();

  let content;

  if (isLoading) content = <LoadingScreen />;

  if (isSuccess) content = <Outlet />;

  if (isError) content = <div>Errore: {error}</div>;

  return content;
};

export default ImpiantiLayout;
