import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAddCantiereQuery, useGetCantieriQuery } from "./cantieriApiSlice";
import { axiosPrivate } from "../../services/axios";
import { API_PATH } from "../../constants/paths";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const CANTIERI_URL = `${API_PATH}/cantieri`;

const initialState = {
  cantieri: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchCantieri = createAsyncThunk(
  "cantieri/fetchCantieri",
  async (cantieri) => {
    return cantieri;
  }
);

export const addCantiere = createAsyncThunk(
  "cantieri/addCantiere",
  async (cantiere) => {
    return cantiere;
  }
);

const cantieriSlice = createSlice({
  name: "cantieri",
  initialState,
  reducers: {
    cantiereAdded: {
      reducer(state, action) {
        state.cantieri.push(action.payload);
      },
      prepare(cantiere) {
        return {
          ...cantiere,
        };
      },
    },
    cantiereFetched: {
      reducer(state, action) {
        state.cantieri = action.payload;
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCantieri.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCantieri.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Adding date and reactions
        const loadedCantieri = action.payload.map((cantiere) => cantiere);

        // Add any fetched posts to the array
        state.cantieri = state.cantieri.concat(loadedCantieri);
      })
      .addCase(fetchCantieri.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addCantiere.fulfilled, (state, action) => {
        state.cantieri.push(action.payload);
      });
  },
});

export const selectAllCantieri = (state) => state.cantieri.cantieri;
export const getCantieriError = (state) => state.cantieri.error;
export const getCantieriStatus = (state) => state.cantieri.status;

export const selectCantiereById = (state, id) =>
  state.cantieri.cantieri.find((cantiere) => cantiere.id === id);

export const { cantiereAdded } = cantieriSlice.actions;

export default cantieriSlice.reducer;
