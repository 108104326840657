import { apiSlice } from "../../app/api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { extendedApiSlice } from "../impianti/impiantiApiSlice";

const cantieriAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.nome.localeCompare(b.nome),
});

const initialState = cantieriAdapter.getInitialState();

export const cantieriApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCantieri: builder.query({
      query: () => "/cantieri",
      transformResponse: (response) => {
        return {
          ...cantieriAdapter.setAll(initialState, response.data),
          aperti: response?.data?.filter(
            (cantiere) => Number(cantiere.stato) === 1
          ),
        };
      },
      providesTags: (result, error, arg) => [
        { type: "Cantieri", id: "LIST" },
        ...result.ids.map((id) => ({
          type: "Cantieri",
          id,
        })),
      ],
    }),
    getUsciteCantiere: builder.query({
      query: (id) => `/impianti/uscite/cantiere/${id}`,
      providesTags: ["Movimenti"],
    }),
    addNewCantiere: builder.mutation({
      query: (cantiere) => ({
        url: "/cantieri",
        method: "POST",
        body: cantiere,
      }),
      invalidatesTags: [{ type: "Cantieri", id: "LIST" }],
    }),
    updateCantiere: builder.mutation({
      query: ({ data, id }) => ({
        url: `/cantieri/${id}`,
        method: "PUT",
        body: data,
      }),

      invalidatesTags: (result, error, arg) => [
        { type: "Cantieri", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCantieriQuery,
  useAddNewCantiereMutation,
  useUpdateCantiereMutation,
  useGetUsciteCantiereQuery,
} = cantieriApiSlice;

// returns the query result object
export const selectCantieriResult =
  extendedApiSlice.endpoints.getCantieri.select();

// Creates memoized selector
const selectCantieriData = createSelector(
  selectCantieriResult,
  (result) => {
    return result.data;
  } // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCantieri,
  selectById: selectCantiereById,
  // Pass in a selector that returns the posts slice of state
} = cantieriAdapter.getSelectors(
  (state) => selectCantieriData(state) ?? initialState
);

export const selectCantieriAperti = createSelector(
  selectCantieriResult,
  (data) => data?.data?.aperti ?? []
);
