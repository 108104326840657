import { Switch } from "@headlessui/react";
import { classNames } from "../../../utilities/generali";

const SwitchUscitaInterna = ({ interno, setInterno }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
      <Switch.Group as="div" className="col-start-2 flex items-center">
        <span className="flex flex-grow flex-col">
          <Switch.Label
            as="span"
            className="text-sm font-medium text-gray-900"
            passive
          >
            Movimento Interno
          </Switch.Label>
          <Switch.Description as="span" className="text-sm text-gray-500">
            Il movimento è verso un nostro cantiere e non verso un cantiere di
            un cliente che acquista il materiale.
          </Switch.Description>
        </span>
        <Switch
          checked={interno}
          onChange={setInterno}
          className={classNames(
            interno ? "bg-casalucci" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-casalucci-400 focus:ring-offset-2"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              interno ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
};

export default SwitchUscitaInterna;
