import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utilities/generali";

const Select = ({
  items = [],
  setSelectedItem,
  selectedItem,
  label = null,
  category = undefined,
  className = "",
  isValid = true,
  errorMessage = "Il campo è obbligatorio",
  ...others
}) => {
  let currentCategory = "";

  return (
    <>
      <Listbox value={selectedItem} onChange={setSelectedItem} {...others}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                {label}
              </Listbox.Label>
            )}

            <div className="relative mt-1">
              <Listbox.Button
                className={classNames(
                  "relative h-9 w-full cursor-default cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-casalucci-400 focus:outline-none focus:ring-1 focus:ring-casalucci-400 sm:text-sm",
                  className,
                  !isValid &&
                    "border-red-500 border-2 ring-red-500 focus:border-red-500 focus:ring-red-500"
                )}
              >
                <span className="block truncate">
                  {selectedItem?.name || selectedItem?.nome}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {items?.map((item) => {
                    let didCategoryChange =
                      category && item[category] !== currentCategory;

                    currentCategory = item[category];

                    return (
                      <div key={item?.id}>
                        <span
                          className={classNames(
                            "ml-3 mt-2 border-gray-900 text-gray-400",
                            !didCategoryChange && "hidden"
                          )}
                        >
                          {item[category]}
                        </span>

                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-casalucci text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={item}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {item?.name || item?.nome}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-casalucci",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      </div>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <p
        className={classNames(
          "mt-2 text-red-500 text-sm",
          isValid ? "hidden" : "block"
        )}
      >
        {errorMessage}
      </p>
    </>
  );
};

export default Select;
