import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    errors: [],
  },
  reducers: {
    addError: (state, action) => {
      state.errors.push(action.payload);
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    clearError: (state, action) => {
      state.errors = state.errors.filter(
        (error) => error?.id !== action?.payload
      );
    },
    editError: (state, action) => {
      state.errors = state.errors.map((error) => {
        if (error?.id === action.payload?.id) {
          return action.payload;
        }

        return error;
      });
    },
  },
});

export const { addError, clearErrors, clearError, editError } =
  errorSlice.actions;

export default errorSlice.reducer;

export const selectErrors = (state) => state.error.errors;
export const selectErrorById = (state, id) =>
  state.error.errors.find((error) => error.id === id);
