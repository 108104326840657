import {
  classNames,
  createImageLink,
  formatoNumeriGrandi,
} from "../../../utilities/generali";
import { CurrencyEuroIcon } from "@heroicons/react/24/solid";

const RisorseTable = ({ risorse }) => {
  return (
    <>
      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Nome
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Costo
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Quantità
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                UM
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Categoria
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {risorse?.map((risorsa, index) => (
              <tr
                key={risorsa.id}
                className={classNames(
                  index % 2 === 0 ? undefined : "bg-gray-50",
                  "hover:bg-casalucci-400 hover:bg-opacity-20"
                )}
              >
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                  <div className="flex items-center">
                    {Boolean(risorsa.foto) ? (
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={createImageLink(risorsa.foto)}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-casalucci-300">
                        <span className="text-xl font-medium leading-none text-white">
                          {risorsa?.nome?.slice(0, 1)}
                        </span>
                      </div>
                    )}
                    <div className="ml-4">
                      {risorsa.nome}
                      <dl className="font-normal lg:hidden">
                        <dd className="mt-1 truncate text-gray-700 flex space-x-3 items-center">
                          {Boolean(risorsa.costo) ? (
                            <>
                              <CurrencyEuroIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              {`${formatoNumeriGrandi(risorsa.costo)}`}
                            </>
                          ) : (
                            "-"
                          )}
                        </dd>

                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-100 text-casalucci-600">
                            {risorsa.categoria}
                          </p>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:flex lg:space-x-3 lg:items-center">
                  {Boolean(risorsa.costo) ? (
                    <>
                      <CurrencyEuroIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {`${formatoNumeriGrandi(risorsa.costo)}`}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {Boolean(risorsa.illimitata) ? "Illimitata" : risorsa.qta}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {risorsa.um}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {Boolean(risorsa.categoria) && (
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-casalucci-100 text-casalucci-600">
                      {risorsa.categoria}
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RisorseTable;
