import { Link } from "react-router-dom";
import { HomeIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import { selectPathChanges } from "../../../features/auth/navigationSlice";

const Breadcrumb = ({ originalPath, ...props }) => {
  const path = [...originalPath];
  const changes = useSelector(selectPathChanges);

  changes.forEach(({ name, newName }) => {
    let index = path.indexOf(name.toString());
    path[index] = newName;
  });

  return (
    <nav
      className="flex h-10 bg-white py-4 pl-8 shadow-md lg:border-t lg:border-t-gray-200 overflow-x-auto pr-4"
      aria-label="Breadcrumb"
      {...props}
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <Link
            to={"/"}
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <HomeIcon className="mr-4 h-5 w-5" />
            Impianti
          </Link>
        </li>
        {path[0] !== "" &&
          path.map((item, index, array) => {
            if (index + 1 === array.length) {
              return (
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="h-6 w-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ChevronRightIcon className="h-5 w-5" />
                    </svg>
                    <span className="ml-1 text-sm font-medium capitalize text-gray-400 dark:text-gray-500 md:ml-2 whitespace-nowrap">
                      {item}
                    </span>
                  </div>
                </li>
              );
            } else {
              let currentPath = originalPath.slice(0, index + 1).join("/");
              return (
                <li key={index}>
                  <div className="flex items-center">
                    <svg
                      className="h-6 w-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ChevronRightIcon className="h-5 w-5" />
                    </svg>
                    <Link
                      to={`/${currentPath}`}
                      className="ml-1 text-sm font-medium capitalize text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white md:ml-2 whitespace-nowrap"
                    >
                      {item}
                    </Link>
                  </div>
                </li>
              );
            }
          })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
