import { classNames } from "../../utilities/generali";
import React from "react";

const WhiteButton = React.forwardRef(
  (
    {
      className = "",
      callback = () => null,
      type = "button",
      children = null,
      ...others
    },
    ref
  ) => {
    return (
      <button
        onClick={callback}
        type={type}
        className={classNames(
          className,
          "inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400 sm:text-sm"
        )}
        ref={ref}
        {...others}
      >
        {children}
      </button>
    );
  }
);

export default WhiteButton;
