import { Outlet } from "react-router-dom";
import { useGetCantieriQuery } from "../cantieriApiSlice";
import LoadingScreen from "../../../components/LoadingScreen";

const CantieriLayout = () => {
  const { isLoading, isSuccess, isError, error } = useGetCantieriQuery();

  let content;

  if (isLoading) content = <LoadingScreen />;

  if (isSuccess) content = <Outlet />;

  if (isError) content = <div>Errore: {error}</div>;

  return content;
};

export default CantieriLayout;
