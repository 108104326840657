import "./index.css";

const LoadingScreen = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center bg-white">
      <div className="loader ease-linear rounded-full border-gray-200 h-12 w-12 mb-4" />
      <h2 className="text-center text-gray-800 text-xl font-semibold">
        Caricamento in corso...
      </h2>
      <p className="w-1/3 text-center text-gray-800">
        Potrebbe volerci qualche secondo.
      </p>
    </div>
  );
};

export default LoadingScreen;
