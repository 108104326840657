import { Link } from "react-router-dom";
import ImpiantiList from "../ImpiantiList";
import { useSelector } from "react-redux";
import { selectAllImpianti } from "../impiantiApiSlice";
import { selectCurrentUser } from "../../auth/authSlice";

const ImpiantiPage = () => {
  const impianti = useSelector(selectAllImpianti);
  const user = useSelector(selectCurrentUser);

  return (
    <section>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              Impianti
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-slate-400">
              Lista degli impianti.
            </p>
          </div>
          {user?.role === 104 ? (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Link
                to="nuovo"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-casalucci px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-casalucci focus:ring-offset-2 sm:w-auto"
              >
                Aggiungi Impianto
              </Link>
            </div>
          ) : undefined}
        </div>
        <ImpiantiList impianti={impianti} />
      </div>
    </section>
  );
};

export default ImpiantiPage;
