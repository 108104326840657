import useRandomWidth from "../../../../hooks/useRandomWitsh";

const UltimiMovimentiSkeleton = () => {
  const [width] = useRandomWidth();
  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <ul
        role="list"
        className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
      >
        {Array.from(Array(5).keys()).map((item) => (
          <li key={item}>
            <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
              <div className="flex min-w-0 flex-1 items-center animate-pulse w-">
                <div className="flex-shrink-0">
                  {/* ICON */}
                  <div className="rounded-full bg-slate-200 h-8 w-8" />
                  {/* ICON */}
                </div>
                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <div className="truncate text-sm font-medium text-casalucci">
                      <div className="h-2 bg-slate-200 rounded" />
                    </div>
                    {Boolean(item % 2) && (
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </div>
                    )}
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      {/* ICON */}
                      <div className="rounded-full bg-slate-200 h-5 w-5 mr-1.5" />
                      {/* ICON */}
                      <div
                        className={`h-2 bg-slate-200 rounded w-${width()}`}
                      />
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div>
                      <div className="text-sm text-gray-700">
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </div>
                      {Boolean(item % 2) && (
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                          <div
                            className={`h-2 bg-slate-200 rounded w-${width()}`}
                          />
                        </div>
                      )}
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <div
                          className={`h-2 bg-slate-200 rounded w-${width()}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-16 w-20 w-24 w-28 w-32 w-36 w-40 w-44 hidden" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UltimiMovimentiSkeleton;
