import Input from "../../../components/forms/Input";
import TextArea from "../../../components/forms/TextArea";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  selectImpiantoById,
  useUpdateImpiantoMutation,
} from "../impiantiApiSlice";
import Select from "../../../components/forms/Select";
import { useSelector } from "react-redux";
import { TIPI_IMPIANTO } from "../constants";
import { createImageLink } from "../../../utilities/generali";
import useNavigation from "../../../hooks/useNavigation";

const AggiornaImpiantoPage = () => {
  const { impiantoId } = useParams();

  const [updateImpianto] = useUpdateImpiantoMutation();

  const oldImpianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([{ name: impiantoId, newName: oldImpianto.nome }]);

  const [selectedTipo, setSelectedTipo] = useState(TIPI_IMPIANTO[0]);
  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [foto, setFoto] = useState(null);

  const canSave = [nome, indirizzo].every(Boolean);

  const navigate = useNavigate();

  const onSaveImpiantoClicked = async (e) => {
    e.preventDefault();

    if (canSave) {
      try {
        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("descrizione", descrizione);
        formData.append("indirizzo", indirizzo);
        formData.append("tipo", selectedTipo.name);
        formData.append("foto", foto);

        await updateImpianto({ data: formData, id: oldImpianto.id }).unwrap();

        setNome("");
        setDescrizione("");
        setIndirizzo("");
        setSelectedTipo(TIPI_IMPIANTO[0]);

        navigate(`/${oldImpianto.id}`);
      } catch (err) {
        console.error("Failed to save new impianto", err);
      }
    }
  };

  const onNomeChanged = (e) => setNome(e.target.value);
  const onDescrizioneChanged = (e) => setDescrizione(e.target.value);
  const onIndirizzoChanged = (e) => setIndirizzo(e.target.value);

  useEffect(() => {
    if (![nome, indirizzo, descrizione].every(Boolean)) {
      setNome(oldImpianto?.nome);
      setDescrizione(oldImpianto?.descrizione);
      setIndirizzo(oldImpianto?.indirizzo);
      setSelectedTipo(
        TIPI_IMPIANTO.find((tipo) => tipo.name === oldImpianto?.tipo)
      );
    }
  }, [oldImpianto]);

  let content;

  content = (
    <section>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveImpiantoClicked}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Nuovo Impianto
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Nome*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="nome"
                  value={nome}
                  setValue={onNomeChanged}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Descrizione
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <TextArea
                  className="max-w-lg"
                  name="descrizione"
                  value={descrizione}
                  setValue={onDescrizioneChanged}
                />

                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Descrizione del tipo di impianto, dei materiali trattati e dei
                  prodotti.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Foto
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="flex items-center">
                  {foto ? (
                    <img
                      className="h-12 w-12 flex-shrink-0 rounded-full bg-gray-100"
                      src={URL.createObjectURL(foto)}
                    />
                  ) : oldImpianto.foto ? (
                    <img
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                      src={createImageLink(oldImpianto.foto)}
                      alt=""
                    />
                  ) : (
                    <span className="h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  )}

                  <label
                    htmlFor="foto"
                    className="cursor-pointer ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <input
                      id="foto"
                      name="foto"
                      type="file"
                      className="sr-only"
                      onChange={(e) => {
                        setFoto(e.target.files[0]);
                      }}
                    />
                    cambia
                  </label>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Indirizzo Impianto*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="indirizzo"
                  value={indirizzo}
                  setValue={onIndirizzoChanged}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Tipo Impianto*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Select
                  className="max-w-lg"
                  selectedItem={selectedTipo}
                  setSelectedItem={setSelectedTipo}
                  items={TIPI_IMPIANTO}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to={`/${impiantoId}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  return content;
};

export default AggiornaImpiantoPage;
