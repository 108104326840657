import Input from "../../../../components/forms/Input";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useId, useState } from "react";
import { useAddNewUscitaMutation } from "../../usciteApiSlice";

import SelectCantiere from "../SelectCantiere";
import SelectCliente from "../SelectCliente";
import SelectRicetta from "../SelectRicetta";
import SwitchUscitaInterna from "../SwitchUscitaInterna";
import UmInput from "../../../../components/forms/UmInput";
import useError from "../../../errors/hooks/useError";
import { generateError } from "../../../errors/utilities";
import LoadingScreen from "../../../../components/LoadingScreen";

const NuovaUscitaForm = ({ prossima }) => {
  const { impiantoId } = useParams();

  const [interno, setInterno] = useState(false);

  const [addNewUscita] = useAddNewUscitaMutation();

  const navigate = useNavigate();
  const from = useLocation().state?.from?.pathname || `/${impiantoId}`;
  const errorId = useId();
  const { addError, clearErrors, allAlerts, clearError } = useError();

  const emptyUscita = {
    numero: prossima,
    data: "",
    destinazione: "",
    qta: "",
    cliente: {},
    cantiere: {},
    ricetta: {},
    idImpianto: parseInt(impiantoId),
  };

  const [uscita, setUscita] = useState(emptyUscita);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    if (e.target.name === "qta") {
      newValue = e.target.value
        .replace(/,/g, ".")
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
    }
    setUscita({
      ...uscita,
      [e.target.name]: newValue,
    });

    if (e.target.name === "data") {
      let numero = uscita.numero;
      let valueYear = newValue.substring(0, 4);
      let bollaYear = numero.substring(
        numero.indexOf("-") + 1,
        numero.lastIndexOf("/")
      );
      let isYearChanged = bollaYear !== valueYear;

      if (isYearChanged) {
        numero = numero.replace(/-.*\//, `-${newValue.substring(0, 4)}/`);

        setUscita({
          ...uscita,
          numero,
        });
      }
    }
  };

  const canSave =
    [
      uscita.numero,
      uscita.data,
      uscita.ricetta,
      uscita.qta,
      uscita.destinazione,
    ].every(Boolean) && [uscita.cliente, uscita.cantiere].some(Boolean);

  const onSaveUscitaClicked = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (canSave) {
      try {
        setLoading(true);
        clearError("422");
        clearError(errorId);
        await addNewUscita({
          ...uscita,
        }).unwrap();

        setUscita(emptyUscita);
        clearErrors();
        navigate(from);
      } catch (err) {
        setLoading(false);
        addError({
          id: errorId,
          type: "Error",
          title: err.data.message,
          show: true,
        });
      }
    } else {
      addError(generateError("422", "Compila tutti i campi", null, "alert"));
    }
  };

  const handleChangeSelect = (item) => {
    if (interno) {
      setUscita({
        ...uscita,
        cantiere: item,
        destinazione: item?.indirizzo,
        cliente: null,
      });
    } else {
      setUscita({
        ...uscita,
        cliente: item,
        cantiere: null,
      });
    }
  };

  const handleChangeRicetta = (ricetta) => {
    setUscita({
      ...uscita,
      ricetta,
    });
  };

  /*
*
* SOLO PERFARMARE RIGHE DEL DB
*
*
*
function randomDate(start, end) {
  return new Date(+start + Math.random() * (end - start))
    .toISOString()
    .split("T")[0];
}

useEffect(() => {
  //const cantiere = cantieri[Math.floor(Math.random() * cantieri.length)];

  const indirizzi = [
    "Vergiate",
    "Golasecca",
    "Sesto Calende",
    "Milano",
    "Tradate",
    "Sesona",
    "Gallarate",
    "Samarate",
    "Busto Arsizio",
    "Legnano",
    "Canegrate",
    "Lavena Ponte Tresa",
    "Inarzo",
    "Barasso",
    "Buguggiate",
    "Varese",
    "Venegono",
    "Castelseprio",
    "Daverio",
    "Gavirate",
  ];
  setUscita({
    ...uscita,
    numero: prossima,
    data: randomDate(new Date("2022-01-01"), new Date()),
    qta: Math.floor(Math.random() * (6 - 1 + 1) + 1),
    ricetta: {
      id: Math.floor(Math.random() * (3 - 1 + 1) + 1),
    },
    cliente: {
      id: Math.floor(Math.random() * (2 - 1 + 1) + 1),
    },
    destinazione: indirizzi[Math.floor(Math.random() * indirizzi.length)],
  });

  async function save() {
    await addNewUscita({
      ...uscita,
    }).unwrap();
  }

  save();
}, [prossima]);
  *
  *
   */

  let content;

  content = (
    <section>
      <div className="mb-4">{allAlerts}</div>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveUscitaClicked}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Numero*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="numero"
                  value={uscita.numero}
                  setValue={onInputChange}
                  errorMessage="Inserire un numero di DDT"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Numero del documento di trasporto associato all'entrata nel
                  magazzino dell'impianto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Data*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="data"
                  type="date"
                  value={uscita.data}
                  setValue={onInputChange}
                  errorMessage="Inserire una data per il movimento"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Data della consegna del materiale.
                </p>
              </div>
            </div>

            <SwitchUscitaInterna interno={interno} setInterno={setInterno} />

            {/* SELEZIONA CANTIERE O CLIENTE */}
            {interno ? (
              <SelectCantiere
                selectedCantiere={uscita.cantiere}
                submitted={submitted}
                handleChange={handleChangeSelect}
              />
            ) : (
              <SelectCliente
                selectedCliente={uscita.cliente}
                submitted={submitted}
                handleChange={handleChangeSelect}
              />
            )}
            {/* SELEZIONA CANTIERE O CLIENTE */}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Destinazione*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="destinazione"
                  value={uscita.destinazione}
                  setValue={onInputChange}
                  errorMessage="Inserire un indirizzo per il DDT"
                  required={submitted}
                />
              </div>
            </div>

            <SelectRicetta
              selectedRicetta={uscita.ricetta}
              submitted={submitted}
              handleChange={handleChangeRicetta}
            />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Qta*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <UmInput
                  name="qta"
                  um={uscita?.ricetta?.um}
                  type="text"
                  value={uscita.qta}
                  setValue={onInputChange}
                  errorMessage="Inserire un valore numerico per la quantità"
                  required={submitted}
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to={`/${impiantoId}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annulla
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Salva
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;

  return content;
};

export default NuovaUscitaForm;
