import { removeDuplicates, sortByProp } from "../utilities/generali";
import { useState } from "react";
import { NOMI_MESI } from "../utilities/date";

const useFilter = (items, prop, label, callBack = (item) => item) => {
  const [option, setOption] = useState({});

  const optionsWithDuplicates = items
    .map((item, index) => ({
      nome: item[prop]?.nome
        ? callBack(item[prop]?.nome)
        : callBack(item[prop]),
      id: item[prop]?.id ?? index,
    }))
    .filter((item) => item.nome);
  const options = sortByProp(
    removeDuplicates(optionsWithDuplicates, ["id", "nome"]),
    "nome"
  );

  const mesiOptions = NOMI_MESI.filter((m) =>
    options.some((o) => o.nome === m)
  ).map((m, index) => ({
    id: index + 1,
    nome: m,
  }));

  const clearFilter = () => setOption({});

  const selectProps = {
    items: label === "Mese" ? mesiOptions : options,
    selectedItem: option,
    setSelectedItem: setOption,
    label: label,
  };

  return [option.nome, selectProps, clearFilter];
};

export default useFilter;
