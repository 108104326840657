import { CurrencyEuroIcon } from "@heroicons/react/24/outline";

const RisorseList = ({ risorse = [] }) => {
  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
        Risorse
      </h2>

      <div className="mt-2 flow-root">
        <ul
          role="list"
          className="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
        >
          {risorse.map((risorsa) => (
            <li key={risorsa.id}>
              <div className="group block py-5 px-4 sm:py-6 sm:px-0">
                <div className="min-w-0 flex-1 px-4">
                  <p className="truncate text-sm font-medium text-casalucci">
                    {risorsa?.nome}
                  </p>
                  <div className="flex justify-between items-center mt-2">
                    <p>Quantità</p>

                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <span className="truncate">
                        {Boolean(risorsa.illimitata)
                          ? "Illimitata"
                          : `${risorsa.qta} ${
                              risorsa?.um.toLowerCase() !== "cad" && risorsa?.um
                            }`}
                      </span>
                    </p>
                  </div>

                  <div className="flex justify-between items-center">
                    <p>Costo Unitario</p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <CurrencyEuroIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="truncate">{risorsa?.costo}</span>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RisorseList;
