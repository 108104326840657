import { useState } from "react";
import { useUpdateEntrataMutation } from "../../entrateApiSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../../../components/forms/Input";
import Select from "../../../../components/forms/Select";
import { selectImpiantoById } from "../../impiantiApiSlice";
import { selectAllClienti } from "../../../clienti/clientiApiSlice";
import UmInput from "../../../../components/forms/UmInput";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingScreen from "../../../../components/LoadingScreen";

const EditaScaricoForm = ({ existingEntrata }) => {
  const { impiantoId } = useParams();
  const navigate = useNavigate();

  const impiantoSelector = useMemo(
    () => (state) => selectImpiantoById(state, Number(impiantoId)),
    [impiantoId]
  );

  const clienti = useSelector(selectAllClienti);
  //const risorse = useSelector(selectAllRisorse);
  const { risorse, ...impianto } = useSelector(impiantoSelector);

  const emptyEntrata = {
    type: "scarico",
    id: existingEntrata.id,
    numero: existingEntrata.numero,
    data: existingEntrata.data,
    cliente: clienti.find(
      (cliente) => Number(cliente.id) === Number(existingEntrata.cliente.id)
    ),
    risorsa: risorse.find(
      (risorsa) =>
        risorsa.risorsa.id === existingEntrata.materiali[0].risorsa.id
    ),
    qta: existingEntrata.materiali[0].qta,
    idImpianto: parseInt(impiantoId),
  };

  const [addNewEntrata] = useUpdateEntrataMutation();

  const [entrata, setEntrata] = useState(emptyEntrata);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    if (e.target.name === "qta") {
      newValue = e.target.value
        .replace(/,/g, ".")
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
    }
    setEntrata({
      ...entrata,
      [e.target.name]: newValue,
    });

    if (e.target.name === "data") {
      let numero = entrata.numero;
      let valueYear = newValue.substring(0, 4);
      let bollaYear = numero.substring(
        numero.indexOf("-") + 1,
        numero.lastIndexOf("/")
      );
      let isYearChanged = bollaYear !== valueYear;

      if (isYearChanged) {
        numero = numero.replace(/-.*\//, `-${newValue.substring(0, 4)}/`);

        setEntrata({
          ...entrata,
          numero,
        });
      }
    }
  };

  const canSave = [
    entrata.numero,
    entrata.data,
    entrata.cliente.nome,
    entrata.risorsa.nome,
    entrata.qta,
  ].every(Boolean);

  const onSaveEntrataClicked = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    //console.log(entrata);

    if (canSave) {
      try {
        setLoading(true);
        await addNewEntrata({
          ...entrata,
        }).unwrap();

        setEntrata(emptyEntrata);
        navigate(`/${impiantoId}`);
      } catch (err) {
        setLoading(false);
        console.error("Errore nel salvataggo della nuova Entrata", err);
      }
    }
  };

  const handleChangeRisorsa = (risorsa) => {
    setEntrata({
      ...entrata,
      risorsa: risorsa,
    });
  };

  const handleChangeCliente = (cliente) => {
    setEntrata({
      ...entrata,
      cliente: cliente,
    });
  };

  let content;

  content = (
    <section>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveEntrataClicked}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Numero*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="numero"
                  value={entrata.numero}
                  setValue={onInputChange}
                  errorMessage="Inserire un numero di DDT"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Numero del documento di trasporto associato all'entrata nel
                  magazzino dell'impianto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Data*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="data"
                  type="date"
                  value={entrata.data}
                  setValue={onInputChange}
                  errorMessage="Inserire una data per il movimento"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Data della consegna del materiale.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Cliente*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Select
                  className="max-w-lg"
                  name="cliente"
                  items={clienti}
                  selectedItem={entrata.cliente}
                  setSelectedItem={handleChangeCliente}
                  errorMessage="Inserire un Cliente per il movimento"
                  required={submitted}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Risorsa*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Select
                  name="risorsa"
                  items={risorse}
                  selectedItem={entrata.risorsa}
                  setSelectedItem={handleChangeRisorsa}
                  errorMessage="Inserire una risorsa per il movimento"
                  required={submitted}
                  className="max-w-lg"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Quantità*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-xs">
                <UmInput
                  name="qta"
                  um={entrata?.risorsa?.um}
                  type="text"
                  value={entrata.qta}
                  setValue={onInputChange}
                  errorMessage="Inserire una quantità per il movimento"
                  required={submitted}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to={`/${impiantoId}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annulla
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Salva
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;
  return content;
};

export default EditaScaricoForm;
