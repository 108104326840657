import { classNames } from "../../utilities/generali";

const PrimaryButton = ({
  className = "",
  callback = () => null,
  type = "button",
  children = null,
  ...others
}) => {
  return (
    <button
      onClick={callback}
      type={type}
      className={classNames(
        className,
        "inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400 sm:text-sm"
      )}
      {...others}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
