import Input from "../../../../components/forms/Input";
import SelectCantiere from "../SelectCantiere";
import SelectCliente from "../SelectCliente";
import SelectRicetta from "../SelectRicetta";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useId, useState } from "react";
import { useUpdateUscitaMutation } from "../../usciteApiSlice";
import LoadingScreen from "../../../../components/LoadingScreen";
import { generateError } from "../../../errors/utilities";
import useError from "../../../errors/hooks/useError";

const EditaUscitaForm = ({ existingUscita }) => {
  const { impiantoId } = useParams();

  const interno = existingUscita.cliente === null;

  const [updateUscita] = useUpdateUscitaMutation();

  const navigate = useNavigate();
  const errorId = useId();
  const { addError, clearErrors, allAlerts, clearError } = useError();

  const emptyUscita = {
    id: existingUscita.id,
    numero: existingUscita.numero,
    data: existingUscita.data,
    destinazione: existingUscita.destinazione,
    qta: existingUscita.qta,
    cliente: existingUscita.cliente,
    cantiere: existingUscita.cantiere,
    ricetta: existingUscita.ricetta,
    idImpianto: parseInt(impiantoId),
  };

  const [uscita, setUscita] = useState(emptyUscita);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    let newValue = e.target.value;
    if (e.target.name === "qta") {
      newValue = e.target.value
        .replace(/,/g, ".")
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
    }
    setUscita({
      ...uscita,
      [e.target.name]: newValue,
    });
  };

  const canSave =
    [
      uscita.numero,
      uscita.data,
      uscita.ricetta,
      uscita.qta,
      uscita.destinazione,
    ].every(Boolean) && [uscita.cliente, uscita.cantiere].some(Boolean);

  const onSaveUscitaClicked = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    if (canSave) {
      try {
        setLoading(true);
        clearError("422");
        clearError(errorId);
        await updateUscita({
          ...uscita,
        }).unwrap();

        setUscita(emptyUscita);
        clearErrors();
        navigate(`/${impiantoId}`);
      } catch (err) {
        setLoading(false);
        addError({
          id: errorId,
          type: "Error",
          title: err?.data?.message,
          show: true,
        });
      }
    } else {
      addError(generateError("422", "Compila tutti i campi", null, "alert"));
    }
  };

  const handleChangeSelect = (item) => {
    if (interno) {
      setUscita({
        ...uscita,
        cantiere: item,
        destinazione: item?.indirizzo,
        cliente: null,
      });
    } else {
      setUscita({
        ...uscita,
        cliente: item,
        cantiere: null,
      });
    }
  };

  const handleChangeRicetta = (ricetta) => {
    setUscita({
      ...uscita,
      ricetta,
    });
  };

  let content = (
    <section>
      <div className="mb-4">{allAlerts}</div>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSaveUscitaClicked}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              I campi indicati con * sono obbligatori.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Numero*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="numero"
                  value={uscita.numero}
                  setValue={onInputChange}
                  errorMessage="Inserire un numero di DDT"
                  required={submitted}
                  disabled
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Numero del documento di trasporto associato all'entrata nel
                  magazzino dell'impianto.
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Data*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="data"
                  type="date"
                  value={uscita.data}
                  setValue={onInputChange}
                  errorMessage="Inserire una data per il movimento"
                  required={submitted}
                />
                <p className="mt-2 text-sm text-gray-500 max-w-lg">
                  Data della consegna del materiale.
                </p>
              </div>
            </div>

            {/* SELEZIONA CANTIERE O CLIENTE */}
            {interno ? (
              <SelectCantiere
                selectedCantiere={uscita.cantiere}
                submitted={submitted}
                handleChange={handleChangeSelect}
              />
            ) : (
              <SelectCliente
                selectedCliente={uscita.cliente}
                submitted={submitted}
                handleChange={handleChangeSelect}
              />
            )}
            {/* SELEZIONA CANTIERE O CLIENTE */}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Destinazione*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-lg"
                  name="destinazione"
                  value={uscita.destinazione}
                  setValue={onInputChange}
                  errorMessage="Inserire un indirizzo per il DDT"
                  required={submitted}
                />
              </div>
            </div>

            <SelectRicetta
              selectedRicetta={uscita.ricetta}
              submitted={submitted}
              handleChange={handleChangeRicetta}
            />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Qta*
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Input
                  className="max-w-xs"
                  name="qta"
                  value={uscita.qta}
                  setValue={onInputChange}
                  errorMessage="Inserire un valore numerico per la quantità"
                  required={submitted}
                  pattern="[+-]?([0-9]*[.])?[0-9]+"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to={`/${impiantoId}/uscite/${uscita.id}`}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Annulla
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-casalucci hover:bg-casalucci-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-casalucci-400"
            >
              Salva
            </button>
          </div>
        </div>
      </form>
    </section>
  );

  if (loading) return <LoadingScreen />;

  return content;
};

export default EditaUscitaForm;
