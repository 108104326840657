const NotFound = () => {
  return (
    <div className="flex justify-center">
      <div className="mt-40 sm:mt-80 text-center sm:mx-24 sm:flex max-w-2xl space-x-8 sm:divide-x sm:divide-gray-500">
        <h1 className="m-auto text-6xl font-extrabold text-casalucci">404</h1>
        <div className="sm:pl-8">
          <h3 className="text-3xl font-extrabold text-gray-800">
            Ciò che cerchi non esiste.
          </h3>
          <p className="mt-2 text-gray-500">
            Se pensi che sia un errore contatta l'amministratore.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
