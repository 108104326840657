import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUsciteSelectors } from "../usciteApiSlice";
import UsciteList from "../components/UsciteList";
import WhiteButton from "../../../components/buttons/WhiteButton";
import useNavigation from "../../../hooks/useNavigation";
import { selectImpiantoById } from "../impiantiApiSlice";
import Select from "../../../components/forms/Select";
import {
  filterArray,
  removeDuplicates,
  sortByProp,
} from "../../../utilities/generali";
import useFilter from "../../../hooks/useFilter";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const UscitePage = () => {
  const navigate = useNavigate();
  const { impiantoId } = useParams();
  const location = useLocation();

  const { selectAllUscite } = useMemo(() => {
    return getUsciteSelectors(Number(impiantoId));
  }, [impiantoId]);

  const uscite = useSelector(selectAllUscite);
  const impianto = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([{ name: impiantoId, newName: impianto?.nome }]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 md:flex justify-between items-center">
        <div>
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Uscite
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Lista di tutti i movimenti in uscita dall'impianto
          </p>
        </div>
        <WhiteButton
          onClick={() =>
            navigate("nuova", {
              state: { from: location },
              replace: true,
            })
          }
          className="mt-4 w-full md:mt-0 md:w-auto"
        >
          Nuova Uscita
        </WhiteButton>
      </div>
      <UsciteList
        uscite={uscite?.sort((a, b) => new Date(b.data) - new Date(a.data))}
      />
    </div>
  );
};

export default UscitePage;
