import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    pathChanges: [],
  },
  reducers: {
    setPathChanges: (state, action) => {
      state.pathChanges = action.payload;
    },
  },
});

export const { setPathChanges } = navigationSlice.actions;

export default navigationSlice.reducer;

export const selectPathChanges = (state) => state.navigation.pathChanges;
