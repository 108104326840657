import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectImpiantoById } from "../impiantiApiSlice";
import useNavigation from "../../../hooks/useNavigation";

const NuovaEntrataPage = ({ Form }) => {
  const { impiantoId, entrataId } = useParams();

  const { nome } = useSelector((state) =>
    selectImpiantoById(state, Number(impiantoId))
  );

  useNavigation([{ name: impiantoId, newName: nome }]);

  const content = (
    <div className="max-w-5xl mx-auto">
      <section className="md:flex md:items-center md:justify-start">
        <div className="py-5">
          <h3 className="text-2xl font-bold leading-6 font-medium text-gray-900">
            Nuova Entrata
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{nome}</p>
        </div>
      </section>
      <Form />
    </div>
  );
  return content;
};

export default NuovaEntrataPage;
